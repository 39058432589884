import { zodResolver } from '@hookform/resolvers/zod'
import {
  Cancel as CancelIcon,
  LockReset as LockResetIcon,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  Divider,
  Link,
  Modal,
  Stack,
  Typography,
} from '@mui/material'
import { HttpStatusCode } from 'axios'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import {
  PasswordElement,
  SelectElement,
  TextFieldElement,
} from 'react-hook-form-mui'
import { PatternFormat } from 'react-number-format'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import {
  changePasswordBySMSCode,
  resetPassword,
  resetPasswordBySMS,
  resetPasswordService,
} from '~/api'
import logo from '~/assets/header_logo.png'
import animationData from '~/assets/ilustracion_loading.json'
import left from '~/assets/left.png'
import right from '~/assets/right.png'
import face from '~/assets/serious_face.png'
import { axios, serverHost } from '~/axios'
import PlatformLanguageSelector from '~/components/LanguageSelector'
import PhoneNumberInput from '~/components/inputs/PhoneNumberInput'
import StrongPasswordCriterias from '~/components/shared/StrongPasswordCriterias'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { updateUser } from '~/redux/slices/user'
import { getToken } from '~/services/auth-services'
import { getFormattedPhoneNumber } from '~/utils/form-utils'
import { ROLES } from '~/utils/role-utils'
import { type Environment } from '~/utils/types/user'

import Lottie from './login/Lottie'

const enviromentsList = ['platform', 'demo', 'testing'] as const
const websiteEnv: (typeof enviromentsList)[number] =
  enviromentsList.find(env => serverHost.includes(env)) ?? 'testing'

const mainPagesByRole = {
  [ROLES.SURVEY_TAKER]: '/surveys',
  [ROLES.FACILITATOR]: '/surveys',
  [ROLES.STAKEHOLDER]: '/solutions',
  [ROLES.FAMILY]: '/my-profile',
} as const

const phoneUtil = PhoneNumberUtil.getInstance()

export default function Login() {
  const navigate = useNavigate()
  const location = useLocation()

  const { t } = useTranslation()

  const user = useAppSelector(state => state.user)

  const platform = websiteEnv === 'platform' ? 'demo' : 'platform'

  useEffect(() => {
    if (user?.token) return
    const targetURL = location.pathname

    if (targetURL === '/' || targetURL.includes('/login')) return

    navigate(`/login?returnURL=${targetURL}`, { replace: true })
  }, [location, navigate, user])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      sx={{
        overflowY: 'auto',
        minHeight: '100vh',
        backgroundColor: theme => theme.palette.background.paper,
      }}
    >
      {/* NOTE: Image Container */}
      <Stack flex={1} sx={{ display: { xs: 'none', lg: 'flex' } }}>
        <Stack
          flex={1}
          alignItems="center"
          justifyContent="center"
          sx={{
            overflowY: 'hidden',
            position: 'relative',
            borderTopRightRadius: 120,
            backgroundColor: theme => theme.palette.background.default,
          }}
        >
          <Box sx={{ height: 200, position: 'absolute', left: 0, top: -5 }}>
            <img src={left} alt="Banner Up" />
          </Box>

          <Lottie options={defaultOptions} height={650} width={650} />

          <Box sx={{ height: 20, position: 'absolute', right: 80 }}>
            <img src={right} alt="Banner Down" />
          </Box>

          <Box
            sx={{
              position: 'absolute',
              left: theme => theme.spacing(1),
              bottom: theme => theme.spacing(2),
            }}
          >
            <PlatformLanguageSelector withTitle />
          </Box>
        </Stack>
      </Stack>

      {/* NOTE: Login Form */}
      <Stack flex={1} sx={{ bgcolor: theme => theme.palette.background.paper }}>
        <Stack
          alignItems="end"
          sx={{ display: { xs: 'none', lg: 'flex' }, p: 2 }}
        >
          <img src={logo} width={50} height={50} alt="Stoplight Logo" />
        </Stack>

        <Stack flex={1} p={2} alignItems="center" justifyContent="center">
          <Box sx={{ maxWidth: 425 }}>
            <Stack
              flexDirection="row"
              justifyContent="center"
              sx={{
                py: 2,
                display: { xs: 'flex', lg: 'none' },
              }}
            >
              <img src={logo} width={75} height={75} alt="Stoplight Logo" />
            </Stack>

            <Typography
              variant="h4"
              fontSize={32}
              fontWeight={700}
              sx={{
                lineHeight: 1.2,
                textAlign: { xs: 'center', lg: 'start' },
                color: theme => theme.palette.primary.main,
              }}
            >
              {websiteEnv === 'platform'
                ? t('views.login.welcomeToPlatform')
                : t('views.login.welcomeToDemo')}
            </Typography>

            <Stack flexDirection="row" gap={0.5} sx={{ my: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{ color: theme => theme.palette.grey[500] }}
              >
                {t('views.login.switchTo')}
              </Typography>
              <Typography variant="subtitle2">
                <a href={`https://${platform}.povertystoplight.org/`}>
                  <Typography variant="subtitle2">{platform}</Typography>
                </a>
              </Typography>
            </Stack>

            <LoginForm />
          </Box>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ position: 'relative' }}
        >
          <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
            <PlatformLanguageSelector withTitle />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}

function LoginForm() {
  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [changePasswordModalData, setChangePasswordModalData] = useState<{
    isOpen: boolean
    userId: number | null
    phoneNumber: string | null
  }>({
    userId: null,
    isOpen: true,
    phoneNumber: null,
  })

  const [searchParams] = useSearchParams()

  const id = searchParams.get('id')
  const token = searchParams.get('token')

  const isResettingPassword = !!token || !!id
  const user = useAppSelector(state => state.user)

  const verificationMethodOptions = [
    { id: 'EMAIL', label: t('views.family.email') },
    { id: 'PHONE', label: t('views.family.phone') },
  ]

  type FormValues = z.infer<typeof formSchema>
  const formSchema = useMemo(() => {
    const requiredError = t('validation.fieldIsRequired')

    const loginSchema = z.object({
      formMode: z.literal('login'),
      username: z.string({ required_error: requiredError }).trim().min(1),
      password: z.string({ required_error: requiredError }).min(1),
    })

    const forgotPasswordSchema = z.object({
      formMode: z.literal('forgotPassword'),
      credentialMode: z.enum(['EMAIL', 'PHONE']),
      username: z.string({ required_error: requiredError }).trim().min(1),
      email: z
        .string()
        .email({ message: t('views.user.form.emailNotValid') })
        .trim()
        .min(1)
        .toLowerCase()
        .optional(),
      phoneCode: z
        .object({
          code: z.string(),
          id: z.string(),
          label: z.string(),
        })
        .optional(),
      phoneNumber: z.string().nullable().optional(),
    })

    const resetPasswordSchema = z.object({
      formMode: z.literal('resetPassword'),
      isPasswordValid: z.boolean().default(false),
      newPassword: z.string({ required_error: requiredError }).min(1),
      newPasswordConfirm: z.string({ required_error: requiredError }).min(1),
    })

    return z
      .discriminatedUnion('formMode', [
        loginSchema,
        forgotPasswordSchema,
        resetPasswordSchema,
      ])
      .superRefine((values, ctx) => {
        if (values.formMode === 'forgotPassword') {
          if (values.credentialMode === 'EMAIL') {
            if (!values.email) {
              ctx.addIssue({
                code: 'custom',
                path: ['email'],
                message: requiredError,
              })
              return z.NEVER
            }
          }

          if (values.credentialMode === 'PHONE') {
            const { phoneCode, phoneNumber } = values
            if (!phoneCode) {
              ctx.addIssue({
                code: 'custom',
                path: ['phoneCode'],
                message: requiredError,
              })
              return z.NEVER
            }

            if (!phoneNumber) {
              ctx.addIssue({
                code: 'custom',
                path: ['phoneNumber'],
                message: requiredError,
              })
              return z.NEVER
            }

            try {
              const international = `+${phoneCode.id} ${phoneNumber}`
              const phone = phoneUtil.parse(international, phoneCode.code)
              const isValid = phoneUtil.isValidNumberForRegion(
                phone,
                phoneCode.code,
              )

              if (!isValid) throw new Error('Invalid Number')
            } catch {
              ctx.addIssue({
                code: 'custom',
                message: t('validation.validPhoneNumber'),
                path: ['phoneNumber'],
              })
            }
          }
        }

        if (values.formMode === 'resetPassword') {
          const { newPassword, newPasswordConfirm, isPasswordValid } = values
          if (newPassword !== newPasswordConfirm) {
            ctx.addIssue({
              code: 'custom',
              path: ['newPasswordConfirm'],
              message: t('views.user.form.passwordConfirmFailed'),
            })

            return z.NEVER
          }

          if (!isPasswordValid) {
            ctx.addIssue({
              code: 'custom',
              message: t('views.user.form.passwordIsNotStrong'),
              path: ['newPassword'],
            })
            return z.NEVER
          }
        }
      })
  }, [t])

  const form = useForm<FormValues>({
    shouldUnregister: true,
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    form.setValue('formMode', isResettingPassword ? 'resetPassword' : 'login')
  }, [isResettingPassword, form])

  function handleFormSublink() {
    const isNotOnLoginForm = form.watch('formMode') !== 'login'

    if (isNotOnLoginForm) {
      form.setValue('formMode', 'login')
      return
    }

    if (isResettingPassword) {
      form.setValue('formMode', 'resetPassword')
      return
    }

    form.setValue('formMode', 'forgotPassword')
  }

  async function onSubmit(formValues: FormValues) {
    closeSnackbar()
    const formData = new FormData()

    if (formValues.formMode === 'login') {
      const { username, password } = formValues

      formData.set('username', username)
      formData.set('password', password)
      formData.set('grant_type', 'password')

      try {
        const response = await getToken(formData)

        const retrievedUser = response.data.user
        const userRole = retrievedUser.authorities[0].authority

        if (retrievedUser.didExpire) {
          enqueueSnackbar(t('views.login.passwordExpired'), {
            variant: 'error',
            persist: true,
          })

          form.setValue('formMode', 'forgotPassword')
          form.setFocus('email')

          return
        }

        if (retrievedUser.willExpire) {
          const message =
            retrievedUser.daysToExpirePassword === 1
              ? t('views.login.passwordWillExpire_one', {
                  count: retrievedUser.daysToExpirePassword,
                })
              : t('views.login.passwordWillExpire_other', {
                  count: retrievedUser.daysToExpirePassword,
                })

          enqueueSnackbar(message, {
            variant: 'passwordWarning',
            persist: true,
          })
        }

        dispatch(
          // @ts-expect-error Email should be `string` but comes as `string | undefined` for some reason
          updateUser({
            ...user,
            ...retrievedUser,
            token: response.data.access_token,
            refreshToken: response.data.refresh_token,
            env: response.data.env as Environment,
            willExpire: response.data.user.willExpire,
            didExpire: response.data.user.didExpire,
            daysToExpirePassword: response.data.user.daysToExpirePassword,
          }),
        )

        const parsedURL = searchParams.get('returnURL')
        const mainPageByRole = mainPagesByRole[userRole] ?? '/dashboard'

        navigate(parsedURL ?? mainPageByRole, { replace: true })
      } catch (error) {
        let errorMessage: null | string = null

        if (error.toString().includes('401'))
          errorMessage = t('views.login.wrongCredentials')
        if (error.toString().includes('403'))
          errorMessage = t('views.login.inactiveUser')
        if (errorMessage === null)
          errorMessage = t('views.login.connectionError')

        enqueueSnackbar(errorMessage, { variant: 'error' })
      }

      return
    }

    if (formValues.formMode === 'forgotPassword') {
      const { credentialMode, username, email, phoneCode, phoneNumber } =
        formValues

      if (credentialMode === 'EMAIL' && email) {
        formData.set('email', email)
        formData.set('username', username)

        try {
          await resetPasswordService(formData)
          form.setValue('formMode', 'login')
          form.setValue('username', '')

          enqueueSnackbar(t('views.login.mailResetSuccess'), {
            variant: 'success',
          })
        } catch (error) {
          enqueueSnackbar(t('views.login.mailNotFound'), { variant: 'error' })
        }
      }

      if (credentialMode === 'PHONE' && phoneNumber && phoneCode) {
        const formattedPhoneNumber = getFormattedPhoneNumber({
          phoneCode,
          phoneNumber,
        })
        formData.set('username', username)
        formData.set('phoneNumber', formattedPhoneNumber)

        try {
          const response = await resetPasswordBySMS(formData)
          setChangePasswordModalData({
            isOpen: true,
            userId: response.data.userId,
            phoneNumber: formattedPhoneNumber,
          })

          enqueueSnackbar(t('validation.successfullySentSms'), {
            variant: 'success',
          })
          form.setValue('formMode', 'login')
        } catch (error) {
          if (!axios.isAxiosError(error)) {
            enqueueSnackbar(t('general.error'))
            return
          }

          const didNotFind = error.response?.status === HttpStatusCode.NotFound
          if (didNotFind) {
            enqueueSnackbar(t('views.login.phoneNotFound'), {
              variant: 'error',
            })
          }
        }
      }

      return
    }

    if (formValues.formMode === 'resetPassword') {
      if (!token || !id) return
      const { newPassword, newPasswordConfirm } = formValues

      const resetPasswordBody = {
        token,
        userId: id,
        password: newPassword,
        repeatPassword: newPasswordConfirm,
      }

      try {
        await resetPassword(resetPasswordBody)
        enqueueSnackbar(t('views.login.passwordReseted'), {
          variant: 'success',
        })

        navigate('/login', { replace: true })
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const errorMessage: unknown = error.response?.data.developerMessage
          if (typeof errorMessage === 'string') {
            enqueueSnackbar(errorMessage, { variant: 'error' })
            return
          }
        }

        enqueueSnackbar(t('views.login.newPasswordConfirmFailed'), {
          variant: 'error',
        })
      }
    }
  }

  const setIsPasswordValid = useCallback(
    (isValid: boolean) => {
      form.setValue('isPasswordValid', isValid)
      void form.trigger('password')
    },
    [form],
  )

  return (
    <>
      {changePasswordModalData.userId &&
        changePasswordModalData.phoneNumber && (
          <ChangePasswordBySmsModal
            userId={changePasswordModalData.userId}
            isOpen={changePasswordModalData.isOpen}
            phoneNumber={changePasswordModalData.phoneNumber}
            setIsOpen={isOpen => {
              setChangePasswordModalData(prev => ({ ...prev, isOpen }))
            }}
            onPasswordChangeSucessful={() => {
              form.setValue('formMode', 'login')
            }}
          />
        )}

      <Stack
        py={1}
        gap={2}
        noValidate
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Collapse in={form.watch('formMode') === 'forgotPassword'}>
          <Alert sx={{ borderRadius: 2 }} severity="info">
            {t('views.login.resetTitle')}
          </Alert>
        </Collapse>

        {form.watch('formMode') === 'login' && (
          <>
            <TextFieldElement
              required
              autoFocus
              name="username"
              variant="outlined"
              label={t('views.login.username')}
              control={form.control}
              sx={{ bgcolor: theme => theme.palette.background.default }}
            />
            <PasswordElement
              required
              name="password"
              variant="outlined"
              label={t('views.login.password')}
              control={form.control}
              sx={{ bgcolor: theme => theme.palette.background.default }}
            />
          </>
        )}

        {form.watch('formMode') === 'forgotPassword' && (
          <>
            <SelectElement
              required
              autoFocus
              control={form.control}
              name="credentialMode"
              label={t('views.user.form.verificationMethod')}
              options={verificationMethodOptions}
              InputProps={{
                sx: {
                  bgcolor: theme => theme.palette.background.default,
                },
              }}
            />

            <TextFieldElement
              required
              name="username"
              variant="outlined"
              control={form.control}
              label={t('views.login.username')}
              sx={{ bgcolor: theme => theme.palette.background.default }}
            />

            <Collapse
              unmountOnExit
              in={form.watch('credentialMode') === 'EMAIL'}
              timeout={{ enter: 200, exit: 0 }}
            >
              <TextFieldElement
                fullWidth
                required
                type="email"
                name="email"
                variant="outlined"
                control={form.control}
                label={t('views.login.email')}
                sx={{ bgcolor: theme => theme.palette.background.default }}
              />
            </Collapse>

            <Collapse
              unmountOnExit
              timeout={{ enter: 200, exit: 0 }}
              in={form.watch('credentialMode') === 'PHONE'}
            >
              <PhoneNumberInput
                fullWidthInputs
                autocompleteProps={{
                  required: true,
                  label: t('views.family.phoneCode'),
                  name: 'phoneCode',
                  control: form.control,
                  autocompleteProps: {
                    autoHighlight: true,
                    sx: { bgcolor: theme => theme.palette.background.default },
                  },
                }}
                textFieldProps={{
                  required: true,
                  label: t('views.family.phone'),
                  name: 'phoneNumber',
                  control: form.control,
                  sx: { bgcolor: theme => theme.palette.background.default },
                }}
              />
            </Collapse>
          </>
        )}

        {form.watch('formMode') === 'resetPassword' && (
          <>
            <PasswordElement
              required
              autoFocus
              name="newPassword"
              variant="outlined"
              label={t('views.login.newPassword')}
              control={form.control}
              sx={{ bgcolor: theme => theme.palette.background.default }}
              onChange={() => {
                if (form.getValues('newPasswordConfirm')) {
                  void form.trigger('newPasswordConfirm')
                }
              }}
            />

            <PasswordElement
              required
              name="newPasswordConfirm"
              variant="outlined"
              label={t('views.login.newPasswordConfirm')}
              control={form.control}
              sx={{ bgcolor: theme => theme.palette.background.default }}
              onChange={() => {
                if (form.getValues('newPassword')) {
                  void form.trigger('newPassword')
                }
              }}
            />

            <Collapse
              unmountOnExit
              in={form.getFieldState('newPassword').isDirty}
            >
              <StrongPasswordCriterias
                password={form.watch('newPassword')}
                setIsPasswordValid={setIsPasswordValid}
              />
            </Collapse>
          </>
        )}

        <Stack direction="row" justifyContent="flex-end">
          <Link
            component="a"
            variant="body1"
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              color: theme =>
                form.formState.isSubmitting
                  ? theme.palette.grey[500]
                  : theme.palette.primary.dark,
            }}
            onClick={() => {
              if (form.formState.isSubmitting) return
              handleFormSublink()
            }}
          >
            {form.watch('formMode') === 'login'
              ? t('views.login.forgotPassword')
              : t('views.login.backLogin')}
          </Link>
        </Stack>

        <LoadingButton
          sx={{ py: 1 }}
          type="submit"
          variant="contained"
          loading={form.formState.isSubmitting}
        >
          <Typography fontSize={18} fontWeight={500}>
            {form.watch('formMode') === 'login'
              ? t('views.login.login')
              : t('views.login.resetPassword')}
          </Typography>
        </LoadingButton>
      </Stack>
    </>
  )
}

interface ChangePasswordBySmsModalProps {
  isOpen: boolean
  userId: number
  phoneNumber: string

  setIsOpen: (isOpen: boolean) => void
  onPasswordChangeSucessful: () => void
}
function ChangePasswordBySmsModal({
  isOpen,
  userId,
  phoneNumber,
  setIsOpen,
  onPasswordChangeSucessful,
}: ChangePasswordBySmsModalProps) {
  const [t] = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const formId = useId()

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  type FormValues = z.infer<typeof formSchema>
  const formSchema = useMemo(() => {
    const requiredError = t('validation.fieldIsRequired')
    const schema = z
      .object({
        verificationCode: z.string().min(6, requiredError),
        isPasswordValid: z.boolean().default(false),
        newPassword: z.string().min(1, requiredError),
        newPasswordConfirm: z.string().min(1, requiredError),

        userId: z.number(),
        phoneNumber: z.string(),
      })
      .superRefine((values, ctx) => {
        const { newPassword, newPasswordConfirm, isPasswordValid } = values

        if (newPassword !== newPasswordConfirm) {
          ctx.addIssue({
            code: 'custom',
            message: t('views.user.form.passwordConfirmFailed'),
            path: ['newPasswordConfirm'],
          })
        }

        if (!isPasswordValid) {
          ctx.addIssue({
            code: 'custom',
            message: t('views.user.form.passwordIsNotStrong'),
            path: ['newPassword'],
          })
          return z.NEVER
        }
      })

    return schema
  }, [t])

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      verificationCode: '',
      isPasswordValid: false,
      newPassword: '',
      newPasswordConfirm: '',
      phoneNumber,
      userId,
    },
  })

  const setIsPasswordValid = useCallback(
    (isValid: boolean) => {
      form.setValue('isPasswordValid', isValid)
      void form.trigger('newPassword')
    },
    [form],
  )

  async function onSubmit(values: FormValues) {
    try {
      const changePasswordBody = {
        userId: values.userId,
        code: values.verificationCode,
        password: values.newPassword,
        repeatPassword: values.newPasswordConfirm,
        phoneNumber: values.phoneNumber,
      }

      await changePasswordBySMSCode(changePasswordBody)
      enqueueSnackbar(t('views.login.passwordReseted'), {
        variant: 'success',
      })

      setIsOpen(false)
      onPasswordChangeSucessful()
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage: unknown = error.response?.data.developerMessage
        if (typeof errorMessage === 'string') {
          enqueueSnackbar(errorMessage, { variant: 'error' })
          return
        }
      }

      enqueueSnackbar(t('general.error'), { variant: 'error' })
    }
  }

  return (
    <>
      <Modal
        open={isOpen}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Card
          variant="outlined"
          sx={{
            p: 2,
            outline: 'none',
            width: { xs: '100%', sm: '90%', md: 700 },
          }}
        >
          <CardContent>
            <Divider>
              <Typography gutterBottom variant="h5" textAlign="center">
                {t('views.toolbar.changePassword')}
              </Typography>
            </Divider>

            <Stack
              pt={2}
              gap={2}
              noValidate
              id={formId}
              component="form"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <TextFieldElement
                required
                fullWidth
                autoFocus
                autoComplete="off"
                control={form.control}
                name="verificationCode"
                label={t('views.family.verificationCode')}
                sx={theme => ({
                  input: {
                    textAlign: 'center',
                    color: theme.palette.primary.main,
                    bgcolor: theme.palette.background.default,
                  },
                })}
                InputProps={{
                  // @ts-expect-error - InputComponent should receive TextField props
                  inputComponent: CustomPatternFormat,
                }}
              />

              <PasswordElement
                required
                name="newPassword"
                variant="outlined"
                label={t('views.login.newPassword')}
                control={form.control}
                sx={{ bgcolor: theme => theme.palette.background.default }}
                onChange={() => {
                  if (form.getValues('newPasswordConfirm')) {
                    void form.trigger('newPasswordConfirm')
                  }
                }}
              />

              <PasswordElement
                required
                name="newPasswordConfirm"
                variant="outlined"
                label={t('views.login.newPasswordConfirm')}
                control={form.control}
                sx={{ bgcolor: theme => theme.palette.background.default }}
                onChange={() => {
                  if (form.getValues('newPassword')) {
                    void form.trigger('newPassword')
                  }
                }}
              />

              <Collapse
                unmountOnExit
                in={form.getFieldState('newPassword').isDirty}
              >
                <StrongPasswordCriterias
                  password={form.watch('newPassword')}
                  setIsPasswordValid={setIsPasswordValid}
                />
              </Collapse>
            </Stack>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              startIcon={<CancelIcon />}
              disabled={form.formState.isSubmitting}
              variant="outlined"
              onClick={() => {
                setIsConfirmationModalOpen(true)
              }}
            >
              {t('general.cancel')}
            </Button>
            <LoadingButton
              type="submit"
              form={formId}
              variant="contained"
              endIcon={<LockResetIcon />}
              loading={form.formState.isSubmitting}
            >
              {t('views.login.resetPassword')}
            </LoadingButton>
          </CardActions>
        </Card>
      </Modal>

      <Modal
        open={isConfirmationModalOpen}
        onClose={() => {
          setIsConfirmationModalOpen(false)
        }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card variant="outlined" sx={{ p: 2 }}>
          <CardContent>
            <Stack
              gap={2}
              alignItems="center"
              sx={{ maxWidth: theme => theme.breakpoints.values.sm }}
            >
              <CardMedia
                image={face}
                component="img"
                alt="Warning icon"
                sx={{ width: 100 }}
              />

              <Divider flexItem>
                <Typography variant="h5">
                  {t('views.exitModal.confirmTitle')}
                </Typography>
              </Divider>

              <Typography
                variant="body1"
                color={theme => theme.palette.grey[700]}
              >
                {t('views.login.exitModalInfo')}
              </Typography>
            </Stack>
          </CardContent>

          <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="outlined"
              onClick={() => {
                setIsConfirmationModalOpen(false)
                setIsOpen(false)
              }}
            >
              {t('general.cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsConfirmationModalOpen(false)
              }}
            >
              {t('views.login.continueReset')}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  )
}

interface CustomPatternFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const CustomPatternFormat = forwardRef<
  HTMLInputElement,
  CustomPatternFormatProps
>((props, ref) => {
  const { onChange, ...other } = props

  return (
    <PatternFormat
      {...other}
      mask="_"
      getInputRef={ref}
      format="# # # # # #"
      allowEmptyFormatting
      onValueChange={values => {
        onChange({ target: { name: props.name, value: values.value } })
      }}
    />
  )
})
