import { Search as SearchIcon } from '@mui/icons-material'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps'
import { useDebounceCallback } from 'usehooks-ts'

interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void
}

export default function PlacesAutocomplete({ onPlaceSelect }: Props) {
  const map = useMap()
  const places = useMapsLibrary('places')

  const { t } = useTranslation()

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>()

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null)

  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null)

  const [predictionResults, setPredictionResults] = useState<
    google.maps.places.AutocompletePrediction[]
  >([])

  const [inputValue, setInputValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!places || !map) return

    setAutocompleteService(new places.AutocompleteService())
    setPlacesService(new places.PlacesService(map))
    setSessionToken(new places.AutocompleteSessionToken())

    return () => {
      setAutocompleteService(null)
    }
  }, [map, places])

  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        return
      }

      setIsLoading(true)

      const request = { input: inputValue, sessionToken }
      const response = await autocompleteService.getPlacePredictions(request)

      setPredictionResults(response.predictions)
      setIsLoading(false)
    },
    [autocompleteService, sessionToken],
  )

  const debouncedFetchPredictions = useDebounceCallback(fetchPredictions, 1000)

  const onChange = useCallback(
    (prediction: google.maps.places.AutocompletePrediction) => {
      if (!places) return

      setIsLoading(true)

      const detailRequestOptions = {
        placeId: prediction.place_id,
        fields: ['geometry', 'name', 'formatted_address'],
        sessionToken,
      }

      const detailsRequestCallback = (
        placeDetails: google.maps.places.PlaceResult | null,
      ) => {
        onPlaceSelect(placeDetails)
        setInputValue(placeDetails?.formatted_address ?? '')
        setSessionToken(new places.AutocompleteSessionToken())

        setIsLoading(false)
      }

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback)
    },
    [onPlaceSelect, places, placesService, sessionToken],
  )

  return (
    <Autocomplete
      options={predictionResults}
      onInputChange={(_event, value) => {
        setInputValue(value)
        void debouncedFetchPredictions(value)
      }}
      onChange={(_event, value) => {
        if (!value) return
        onChange(value)
      }}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={t('views.location.searchBoxPlaceholder')}
          InputProps={{
            ...params.InputProps,
            sx: {
              bgcolor: theme => theme.palette.background.default,
              width: { xs: '300px', sm: '400px' },
              marginTop: '10px',
              marginLeft: '10px',
              boxShadow: 1,

              '& fieldset': { border: 'none' },
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      getOptionLabel={option => option.description}
      isOptionEqualToValue={(option, value) =>
        option.description === value.description
      }
      loading={isLoading}
      inputValue={inputValue}
      autoHighlight
    />
  )
}
