import { type ReactNode } from 'react'

import { Button, CircularProgress, Modal, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

interface ConfirmationModalProps {
  open: boolean
  disabledFacilitator: boolean
  onClose: any
  title: string
  subtitle: ReactNode
  confirmAction: () => void
  continueButtonText: string
  cancelButtonText: string
}

export default function ConfirmationModal({
  open,
  disabledFacilitator,
  onClose,
  title,
  subtitle,
  confirmAction,
  continueButtonText,
  cancelButtonText,
}: ConfirmationModalProps) {
  const classes = useStyles()

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.confirmationModal}>
        <Typography variant="h5" test-id="modal-title">
          {title}
        </Typography>

        <Typography
          className={classes.confirmationModalSubtitle}
          variant="subtitle1"
        >
          {subtitle}
        </Typography>

        {disabledFacilitator && <CircularProgress />}

        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            color="primary"
            onClick={confirmAction}
            disabled={disabledFacilitator}
          >
            {continueButtonText || 'Continue'}
          </Button>

          <Button
            variant="outlined"
            color="error"
            onClick={onClose}
            disabled={disabledFacilitator}
          >
            {cancelButtonText || 'Cancel'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const useStyles = makeStyles(theme => ({
  confirmationModal: {
    width: '90vw',
    height: '80vh',
    maxWidth: 370,
    maxHeight: 330,
    backgroundColor: theme.palette.background.default,
    outline: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    padding: '25px 40px',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  confirmationModalSubtitle: {
    textAlign: 'center',
  },
}))
