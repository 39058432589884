import {
  CardMedia,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import {
  AutocompleteElement,
  type AutocompleteElementProps,
  type FieldValues,
  TextFieldElement,
  type TextFieldElementProps,
} from 'react-hook-form-mui'

import { callingCodes } from '~/utils/calling-codes'

const options = callingCodes.map(option => ({
  id: option.value,
  label: option.country,
  code: option.code,
}))

type CustomAutocompleteProps<TFieldValues extends FieldValues> = Omit<
  AutocompleteElementProps<TFieldValues>,
  'options'
>

interface PhoneNumberInputProps<TFieldValues extends FieldValues> {
  fullWidthInputs?: boolean
  autocompleteProps: CustomAutocompleteProps<TFieldValues>
  textFieldProps: TextFieldElementProps<TFieldValues>
}

export default function PhoneNumberInput<TFieldValues extends FieldValues>({
  fullWidthInputs = false,
  autocompleteProps,
  textFieldProps,
}: PhoneNumberInputProps<TFieldValues>) {
  return (
    <Grid container spacing={2}>
      {/* NOTE: Remove the "fullWidthInputs" flag once we migrate to MUI 6 with container queries
        https://next.mui.com/material-ui/customization/container-queries/
      */}
      <Grid item xs={12} sm={fullWidthInputs ? 12 : 5}>
        <AutocompleteElement
          {...autocompleteProps}
          options={options}
          autocompleteProps={{
            ...autocompleteProps.autocompleteProps,
            getOptionLabel: option => `${option.label} (+${option.id})`,
            renderOption: (props, option) => (
              <ListItem dense {...props} key={option.label}>
                <ListItemAvatar>
                  <CardMedia
                    sx={{ width: 40, borderRadius: 0.6 }}
                    component="img"
                    src={`https://flagcdn.com/${option.code.toLowerCase()}.svg`}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={option.label}
                  secondary={`+${option.id}`}
                  primaryTypographyProps={{ fontSize: 16 }}
                  secondaryTypographyProps={{ fontSize: 14 }}
                />
              </ListItem>
            ),
          }}
        />
      </Grid>

      {/* NOTE: Remove the "fullWidthInputs" flag once we migrate to MUI 6 with container queries
        https://next.mui.com/material-ui/customization/container-queries/
      */}
      <Grid item xs={12} sm={fullWidthInputs ? 12 : 7}>
        <TextFieldElement fullWidth {...textFieldProps} />
      </Grid>
    </Grid>
  )
}
