import { zodResolver } from '@hookform/resolvers/zod'
import {
  CheckCircle as CheckCircleIcon,
  ForwardToInbox as ForwardToInboxIcon,
  PublishedWithChanges as PublishedWithChangesIcon,
  SendToMobile as SendToMobileIcon,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { type TFunction } from 'i18next'
import { useSnackbar } from 'notistack'
import { useForm, useWatch } from 'react-hook-form'
import {
  PasswordElement,
  SelectElement,
  TextFieldElement,
} from 'react-hook-form-mui'
import { PatternFormat } from 'react-number-format'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDebounceCallback, useLocalStorage } from 'usehooks-ts'
import { z } from 'zod'

import { checkSessionToken, checkUserName } from '~/api'
import checkAnimation from '~/assets/check.gif'
import TitleBar from '~/components/TitleBar'
import PhoneNumberInput from '~/components/inputs/PhoneNumberInput'
import StrongPasswordCriterias from '~/components/shared/StrongPasswordCriterias'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { updateBackupUser } from '~/redux/slices/backupUser'
import { updateUser } from '~/redux/slices/user'
import { getToken } from '~/services/auth-services'
import {
  createFamilyUser,
  normalizeDraftForSnapshot,
  saveAnonymousSnapshotDraft,
  sendEmailVerificationCode,
  sendPhoneVerificationCode,
  verifyEmailVerificationCode,
  verifyPhoneVerificationCode,
} from '~/services/lifemap-survey-services'
import { callingCodes } from '~/utils/calling-codes'
import { getFormattedPhoneNumber } from '~/utils/form-utils'
import { useRole } from '~/utils/hooks/useRole'
import { ROLES } from '~/utils/role-utils'
import { type Environment } from '~/utils/types/user'
import { excludeFalsyWithMessage } from '~/utils/zod'

const usernameRegEx = /^[a-z0-9]+([._]?[a-z0-9]+)*$/
const phoneUtil = PhoneNumberUtil.getInstance()

function getFormSchema(t: TFunction) {
  const requiredError = t('validation.fieldIsRequired')
  const excludeFalsy = excludeFalsyWithMessage(requiredError)

  const defaultSchema = z.object({
    verificationMethod: z.enum(['EMAIL', 'PHONE'], {
      errorMap: () => ({ message: requiredError }),
    }),
    verificationCode: z.string().min(6, requiredError),
    isPasswordValid: z.boolean().default(false),
    isCodeVerified: z.boolean().default(false),
    username: z
      .string()
      .trim()
      .min(1, requiredError)
      .max(30, t('views.user.form.usernameLengthExceeded'))
      .regex(usernameRegEx, t('views.user.form.usernameInvalid')),
    password: z.string().min(1, requiredError),
    repeatPassword: z.string().min(1, requiredError),
  })

  const emailSchema = z.object({
    verificationMethod: z.literal('EMAIL'),
    email: z.string().email(),
  })

  const phoneSchema = z.object({
    verificationMethod: z.literal('PHONE'),
    phoneCode: z
      .object({
        code: z.string(),
        id: z.string(),
        label: z.string(),
      })
      .nullable()
      .transform(excludeFalsy),
    phoneNumber: z.string().min(1, requiredError),
  })

  const schema = z.discriminatedUnion('verificationMethod', [
    emailSchema,
    phoneSchema,
  ])

  return z.intersection(defaultSchema, schema).superRefine((values, ctx) => {
    if (values.verificationMethod === 'PHONE') {
      const { phoneCode, phoneNumber } = values

      if (!phoneCode) {
        ctx.addIssue({
          code: 'custom',
          message: t('validation.validPhoneNumber'),
          path: ['phoneCode'],
        })
      }

      try {
        const international = `+${phoneCode.id} ${phoneNumber}`
        const phone = phoneUtil.parse(international, phoneCode.code)
        const isValid = phoneUtil.isValidNumberForRegion(phone, phoneCode.code)

        if (!isValid) throw new Error('Invalid Number')
      } catch {
        ctx.addIssue({
          code: 'custom',
          message: t('validation.validPhoneNumber'),
          path: ['phoneNumber'],
        })
      }
    }

    if (values.password !== values.repeatPassword) {
      ctx.addIssue({
        code: 'custom',
        message: t('views.user.form.passwordConfirmFailed'),
        path: ['repeatPassword'],
      })
    }

    if (!values.isPasswordValid) {
      ctx.addIssue({
        code: 'custom',
        message: t('views.user.form.passwordConfirmFailed'),
        path: ['password'],
      })
    }

    if (!values.isCodeVerified) {
      ctx.addIssue({
        code: 'custom',
        message: 'Verify your code',
        path: ['verificationCode'],
      })
    }
  })
}

type FormValuesInput = z.input<ReturnType<typeof getFormSchema>>
type FormValuesOutput = z.output<ReturnType<typeof getFormSchema>>

type Phone = Pick<
  Extract<FormValuesOutput, { verificationMethod: 'PHONE' }>,
  'phoneCode' | 'phoneNumber'
>

export default function CreateUser() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    const { organization } = state.user
    if (!organization) throw new Error('No organization')
    return { ...state.user, organization }
  })

  const { userHasAnyRole } = useRole()

  const currentDraft = useAppSelector(state => {
    if (!state.currentDraft) throw new Error('No currentDraft')
    return state.currentDraft
  })

  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [userCreationMode, setUserCreationMode] = useLocalStorage<
    'PHONE' | 'EMAIL' | null
  >('user-creation-mode', null)

  const maybeProjectId = location.state.projectId as unknown
  const projectId = z
    .number({ invalid_type_error: 'No projectId' })
    .nullable()
    .parse(maybeProjectId)

  const form = useForm<FormValuesInput>({
    resolver: zodResolver(getFormSchema(t)),
    mode: 'onChange',
    defaultValues: {
      verificationMethod: 'EMAIL',
      email: '',
      verificationCode: '',
      isCodeVerified: false,
      isPasswordValid: false,
      username: '',
      password: '',
      repeatPassword: '',
    },
  })

  const setIsPasswordValid = useCallback(
    (isValid: boolean) => {
      form.setValue('isPasswordValid', isValid)
      void form.trigger('password')
    },
    [form],
  )

  const sendEmailCode = useMutation({
    mutationFn: async (email: string) => await sendEmailVerificationCode(email),
    onSuccess(data, _variables, _context) {
      const isSuccess = data.sendEmailVerificationCode.successful
      if (isSuccess) {
        enqueueSnackbar(t('validation.successfullySentEmail'), {
          variant: 'success',
        })
      }
    },
    onError: () => {
      enqueueSnackbar(t('general.error'), { variant: 'error' })
    },
  })

  const verifyEmailCode = useMutation({
    mutationFn: async (payload: { email: string; code: string }) => {
      form.setValue('isCodeVerified', false)

      return await verifyEmailVerificationCode(payload.email, payload.code)
    },
    onSuccess: data => {
      const status = data.verifyEmailCode.content
      const isSuccess = status === 'SUCCESSFUL'

      if (isSuccess) {
        enqueueSnackbar(t('views.family.codeVerified'), { variant: 'success' })
        form.setValue('isCodeVerified', true)
        void form.trigger('verificationCode')
        return
      }

      const errorMessage = t('validation.invalidEmailCode')

      enqueueSnackbar(errorMessage, { variant: 'error' })
      form.setValue('isCodeVerified', false)
    },
    onError: () => {
      enqueueSnackbar(t('general.error'), { variant: 'error' })
      form.setValue('isCodeVerified', false)
    },
  })

  const sendPhoneCode = useMutation({
    mutationFn: async (phone: Phone) =>
      await sendPhoneVerificationCode(getFormattedPhoneNumber(phone)),
    onSuccess: () => {
      enqueueSnackbar(t('validation.successfullySentSms'), {
        variant: 'success',
      })
    },
    onError: () => {
      enqueueSnackbar(t('general.error'), { variant: 'error' })
    },
  })

  const verifyPhoneCode = useMutation({
    mutationFn: async (payload: Phone & { code: string }) => {
      form.setValue('isCodeVerified', false)
      return await verifyPhoneVerificationCode(
        getFormattedPhoneNumber(payload),
        payload.code,
      )
    },
    onSuccess: data => {
      const status = data.verifySMSCode.content
      const isSuccess = status === 'SUCCESSFUL'

      if (isSuccess) {
        enqueueSnackbar(t('views.family.codeVerified'), { variant: 'success' })
        form.setValue('isCodeVerified', true)
        void form.trigger('verificationCode')
        return
      }

      const errorMessage = t('validation.invalidSmsCode')

      enqueueSnackbar(errorMessage, { variant: 'error' })
      form.setValue('isCodeVerified', false)
    },
    onError: () => {
      enqueueSnackbar(t('general.error'), { variant: 'error' })
      form.setValue('isCodeVerified', false)
    },
  })

  const verifyUsername = useQuery({
    queryKey: ['checkUserName', form.getValues('username')],
    queryFn: async () => await checkUserName(form.getValues('username')),
    enabled: false,
  })

  const createUser = useMutation({
    mutationFn: async (values: FormValuesOutput) => {
      const projects = projectId ? [{ id: projectId }] : []
      return await createFamilyUser(
        {
          username: values.username,
          password: values.password,
          // @ts-expect-error the type of the `hub` key probably should be optional or null
          hub: user.hub.id,
          // NOTE: See the type definition of `user.organization.id` to see why
          // we need the non-null assertion
          organization: user.organization.id!,
          email: values.verificationMethod === 'EMAIL' ? values.email : '',
          name: values.username,
          role: ROLES.FAMILY,
          projects,
          active: true,
          permissions: null,
          phoneNumber:
            values.verificationMethod === 'PHONE'
              ? getFormattedPhoneNumber({
                  phoneCode: values.phoneCode,
                  phoneNumber: values.phoneNumber,
                })
              : '',
        },
        values.verificationMethod,
        values.verificationCode,
      )
    },
    onSuccess: () => {
      enqueueSnackbar(t('views.user.userCreated'), { variant: 'success' })
    },
    onError: () => {
      enqueueSnackbar(t('general.error'), { variant: 'error' })
    },
  })

  const debouncedVerifyUsername = useDebounceCallback(
    verifyUsername.refetch,
    1000,
  )

  useEffect(() => {
    if (verifyUsername.data?.data) {
      form.setError('username', {
        type: 'custom',
        message: t('views.user.form.usernameUsed'),
      })
    }
  }, [form, t, verifyUsername.data])

  const verificationMethodOptions = [
    { id: 'EMAIL', label: t('views.family.email') },
    { id: 'PHONE', label: t('views.family.phone') },
  ]

  const formWatch = useWatch({ control: form.control })
  const passwordWatch = formWatch.password ?? ''
  const isCodeVerifiedWatch = formWatch.isCodeVerified
  const verificationMethodWatch = formWatch.verificationMethod
  const verificationCodeWatch = formWatch.verificationCode ?? ''

  const emailWatch = 'email' in formWatch ? formWatch.email : ''
  const phoneNumberWatch =
    'phoneNumber' in formWatch ? formWatch.phoneNumber : ''

  const formErrors = form.formState.errors
  const hasPhoneOrEmailValue = !!emailWatch || !!phoneNumberWatch
  const isPhoneOrEmailValid =
    verificationMethodWatch === 'EMAIL'
      ? hasPhoneOrEmailValue && !('email' in formErrors)
      : hasPhoneOrEmailValue && !('phoneNumber' in formErrors)

  const shouldHideForm = userHasAnyRole([ROLES.FAMILY])

  const defaultPhoneCode = useMemo(() => {
    const countryCode =
      currentSurvey.surveyConfig.surveyLocation.country === 'CA'
        ? 'US'
        : currentSurvey.surveyConfig.surveyLocation.country

    const code = callingCodes.find(({ code }) => code === countryCode)

    return code
      ? { id: code.value, label: code.country, code: code.code }
      : null
  }, [currentSurvey.surveyConfig.surveyLocation.country])

  function resetVerificationCode() {
    form.setValue('verificationCode', '')
    form.setValue('isCodeVerified', false)
    sendPhoneCode.reset()
  }

  function sendVerificationCode() {
    const values = form.getValues()

    if (values.verificationMethod === 'EMAIL') {
      const { email } = values
      sendEmailCode.mutate(email)
    }

    if (values.verificationMethod === 'PHONE') {
      const { phoneCode, phoneNumber } = values
      if (!phoneCode) throw new Error('No phoneCode')
      sendPhoneCode.mutate({ phoneCode, phoneNumber })
    }
  }

  function verifyCode() {
    const values = form.getValues()

    if (values.verificationMethod === 'EMAIL') {
      const { email, verificationCode } = values
      verifyEmailCode.mutate({
        email,
        code: verificationCode,
      })
    }

    if (values.verificationMethod === 'PHONE') {
      const { phoneCode, phoneNumber, verificationCode } = values
      if (!phoneCode) throw new Error('No phoneCode')
      verifyPhoneCode.mutate({
        phoneNumber,
        phoneCode,
        code: verificationCode,
      })
    }
  }

  function handleRedirect() {
    const isJustSToplight =
      currentDraft.justStoplight ?? currentDraft.justStoplightRetake ?? false

    if (isJustSToplight) {
      navigate('/lifemap/stoplight/0', {
        state: { projectId, agreedTerms: true },
      })
      return
    }

    if (currentSurvey.surveyConfig.isSurveyAnonymous) {
      navigate('/lifemap/location', {
        state: { projectId, agreedTerms: true },
      })
      return
    }

    navigate('/lifemap/primary-participant', {
      state: { projectId, agreedTerms: true },
    })
  }

  async function onSubmit(values: FormValuesOutput) {
    const usernameValidation = await verifyUsername.refetch()
    const isUsernameUsed = usernameValidation.data?.data ?? false

    if (isUsernameUsed) return

    await createUser.mutateAsync(values)

    const formData = new FormData()
    formData.set('username', values.username)
    formData.set('password', values.password)

    formData.set('grant_type', 'password')

    // Warning: We're not handling any errors as of right now. What should we do if the user created their user
    // successfully but for some reason couldn't log in? If they retry to create it, they aren't gonna be able to do it
    // as the username is already taken. Just something to think about :)
    const tokenResponse = await getToken(formData)
    const sessionTokenResponse = await checkSessionToken(
      tokenResponse.data.access_token,
    )

    const retrievedUser = tokenResponse.data
    const normalizedDraft = normalizeDraftForSnapshot(currentDraft)
    void saveAnonymousSnapshotDraft(normalizedDraft, retrievedUser.access_token)

    dispatch(updateBackupUser(user))
    dispatch(
      updateUser({
        permissions: sessionTokenResponse.data.permissions,
        username: sessionTokenResponse.data.username,
        id: sessionTokenResponse.data.userId,
        token: retrievedUser.access_token,
        refreshToken: retrievedUser.refresh_token,
        env: retrievedUser.env as Environment,
        willExpire: retrievedUser.user.willExpire,
        didExpire: retrievedUser.user.didExpire,
        daysToExpirePassword: retrievedUser.user.daysToExpirePassword,
        role: sessionTokenResponse.data.role,
        hub: sessionTokenResponse.data.application,
        organization: sessionTokenResponse.data.organization,
        stakeholder: sessionTokenResponse.data.stakeholder,
        name: sessionTokenResponse.data.name,
        email: sessionTokenResponse.data.email,
        phoneNumber: sessionTokenResponse.data.phoneNumber,
        interactive_help:
          !!sessionTokenResponse.data.application &&
          !!sessionTokenResponse.data.application.interactiveHelp,
        sessionExpired: false,
        sessionStart: user.sessionStart
          ? user.sessionStart
          : new Date().getTime(),
        dynamicLink: user.dynamicLink,
      }),
    )

    setUserCreationMode(values.verificationMethod)
    handleRedirect()
  }

  if (shouldHideForm) {
    return (
      <>
        <TitleBar title={t('views.user.createUser')} />
        <Container maxWidth="md">
          <Stack alignItems="center" gap={2}>
            <CardMedia
              component="img"
              image={checkAnimation}
              sx={{
                width: 'auto',
                height: 200,
                objectFit: 'contain',
              }}
            />

            <Divider flexItem>
              <Typography variant="h4">
                {t('views.lifemap.userAlreadyCreated')}
              </Typography>
            </Divider>

            <Alert severity="info">
              {t('views.lifemap.continueForwardToTakeSurvey')}
            </Alert>

            <Button onClick={handleRedirect} variant="contained">
              {t('general.continue')}
            </Button>
          </Stack>
        </Container>
      </>
    )
  }

  return (
    <>
      <TitleBar title={t('views.user.createUser')} />

      <Container maxWidth="md" sx={{ marginY: 5 }}>
        <Stack
          component="form"
          spacing={2}
          noValidate
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <SelectElement
            required
            control={form.control}
            name="verificationMethod"
            label={t('views.user.form.verificationMethod')}
            options={verificationMethodOptions}
            onChange={() => {
              resetVerificationCode()
              form.setValue('email', '')
              form.setValue('phoneNumber', '')
              form.setValue('phoneCode', defaultPhoneCode)
            }}
          />

          {verificationMethodWatch === 'PHONE' && (
            <Box>
              <PhoneNumberInput
                autocompleteProps={{
                  required: true,
                  label: t('views.family.phoneCode'),
                  control: form.control,
                  name: 'phoneCode',
                  autocompleteProps: {
                    autoHighlight: true,
                    onChange: () => {
                      if (isCodeVerifiedWatch) resetVerificationCode()
                      if (form.getValues('phoneNumber')) {
                        void form.trigger('phoneNumber')
                      }
                    },
                  },
                }}
                textFieldProps={{
                  required: true,
                  label: t('views.family.phone'),
                  control: form.control,
                  name: 'phoneNumber',
                  onChange: () => {
                    if (isCodeVerifiedWatch) resetVerificationCode()
                  },
                }}
              />
            </Box>
          )}
          {verificationMethodWatch === 'EMAIL' && (
            <TextFieldElement
              control={form.control}
              name="email"
              label={t('views.family.email')}
              required
              type="email"
              onChange={() => {
                if (isCodeVerifiedWatch) resetVerificationCode()
              }}
            />
          )}

          <LoadingButton
            variant="outlined"
            disabled={!isPhoneOrEmailValid}
            loading={sendPhoneCode.isLoading || sendEmailCode.isLoading}
            endIcon={
              verificationMethodWatch === 'PHONE' ? (
                <SendToMobileIcon />
              ) : (
                <ForwardToInboxIcon />
              )
            }
            onClick={sendVerificationCode}
          >
            {t('views.family.sendVerificationCode')}
          </LoadingButton>

          <Box
            component="fieldset"
            sx={{ border: 0, padding: 0 }}
            disabled={
              (!sendEmailCode.isSuccess && !sendPhoneCode.isSuccess) ||
              verifyEmailCode.isLoading ||
              verifyPhoneCode.isLoading ||
              isCodeVerifiedWatch
            }
          >
            <TextFieldElement
              required
              fullWidth
              autoComplete="off"
              control={form.control}
              name="verificationCode"
              label={t('views.family.verificationCode')}
              sx={theme => ({
                input: {
                  textAlign: 'center',
                  color: theme.palette.primary.main,
                },
              })}
              InputProps={{
                // @ts-expect-error - InputComponent should receive TextField props
                inputComponent: CustomPatternFormat,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      disabled={
                        verificationCodeWatch.length < 6 ||
                        verifyEmailCode.isLoading ||
                        verifyPhoneCode.isLoading ||
                        isCodeVerifiedWatch
                      }
                      onClick={verifyCode}
                    >
                      {isCodeVerifiedWatch && <CheckCircleIcon />}
                      {(verifyEmailCode.isLoading ||
                        verifyPhoneCode.isLoading) && (
                        <CircularProgress size={25} />
                      )}
                      {!isCodeVerifiedWatch &&
                        !verifyEmailCode.isLoading &&
                        !verifyPhoneCode.isLoading && (
                          <Tooltip
                            arrow
                            open={verificationCodeWatch.length === 6}
                            title={t('views.family.verifyCodeHelperText')}
                          >
                            <PublishedWithChangesIcon />
                          </Tooltip>
                        )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <TextFieldElement
            required
            name="username"
            control={form.control}
            label={t('views.user.form.username')}
            onChange={() => {
              if (form.getValues('username')) {
                void debouncedVerifyUsername()
              }
            }}
          />

          <PasswordElement
            control={form.control}
            name="password"
            label={t('views.login.password')}
            required
            onChange={() => {
              if (form.getValues('repeatPassword')) {
                void form.trigger('repeatPassword')
              }
            }}
          />

          <PasswordElement
            required
            control={form.control}
            name="repeatPassword"
            label={t('views.login.newPasswordConfirm')}
          />

          <Collapse
            in={!!passwordWatch}
            sx={{ display: passwordWatch ? 'block' : 'none' }}
          >
            <StrongPasswordCriterias
              password={passwordWatch}
              setIsPasswordValid={setIsPasswordValid}
            />
          </Collapse>

          <Box sx={{ textAlign: 'center' }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={form.formState.isSubmitting}
            >
              {t('views.user.createUser')}
            </LoadingButton>
          </Box>
        </Stack>
      </Container>
    </>
  )
}

interface CustomPatternFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const CustomPatternFormat = forwardRef<
  HTMLInputElement,
  CustomPatternFormatProps
>((props, ref) => {
  const { onChange, ...other } = props

  return (
    <PatternFormat
      {...other}
      mask="_"
      getInputRef={ref}
      format="# # # # # #"
      allowEmptyFormatting
      onValueChange={values => {
        onChange({ target: { name: props.name, value: values.value } })
      }}
    />
  )
})
