import { Check } from '@mui/icons-material'
import {
  Avatar,
  Box,
  CardMedia,
  Container,
  Grow,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material'
import { renderToStaticMarkup } from 'react-dom/server'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import {
  Autoplay,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from 'swiper/modules'
import { Swiper, type SwiperRef, SwiperSlide } from 'swiper/react'

import { MUIWrapperContext } from '~/components/providers/MuiWrapperProvider'
import theme, { COLORS } from '~/theme'
import { type LifemapLevel } from '~/utils/types/surveys'

//* NOTE: Fifteen may seem like a lot of time, but
//* is required to let users read the indicators
const INTERACTIVE_DELAY_IN_MILISECONDS = 15000

const colorValues = {
  1: { bgColor: COLORS.RED, textColor: theme.palette.primary.contrastText },
  2: { bgColor: COLORS.YELLOW, textColor: theme.palette.text.primary },
  3: { bgColor: COLORS.GREEN, textColor: theme.palette.primary.contrastText },
}

type QuestionValue = 1 | 2 | 3 | 9 | 0

interface StopLightQuestionCarouselProps {
  codeName: string
  interactive?: boolean
  options: LifemapLevel[]
  answeredValue: QuestionValue | null
  submitQuestion: (value: QuestionValue) => void
}

function StopLightQuestionCarousel({
  options,
  codeName,
  answeredValue,
  submitQuestion,
}: StopLightQuestionCarouselProps) {
  const swiperRef = useRef<SwiperRef>(null)
  const muiBreakpoints = theme.breakpoints.values

  const { currentDirection } = useContext(MUIWrapperContext)

  const serializedOptions = useMemo(
    () => (currentDirection === 'rtl' ? options.reverse() : options),
    [currentDirection, options],
  )

  return (
    <Container sx={{ my: 2, position: 'relative' }} key={codeName}>
      <Swiper
        ref={swiperRef}
        spaceBetween={50}
        slidesPerView={3}
        modules={[Pagination, Navigation, Keyboard, Mousewheel, Autoplay]}
        autoplay={{
          stopOnLastSlide: true,
          disableOnInteraction: true,
          delay: INTERACTIVE_DELAY_IN_MILISECONDS,
        }}
        mousewheel={{ forceToAxis: true }}
        keyboard={{ enabled: true, pageUpDown: true }}
        pagination={{
          clickable: true,
          renderBullet(idx, className) {
            return renderToStaticMarkup(
              <CustomBullet idx={idx} className={className} />,
            )
          },
        }}
        breakpoints={{
          [muiBreakpoints.lg]: { slidesPerView: 3 },
          [muiBreakpoints.sm]: { slidesPerView: 2 },
          [muiBreakpoints.xs]: { slidesPerView: 1 },
        }}
      >
        {serializedOptions.map((option, idx) => (
          <SwiperSlide key={idx} style={{ height: 'auto', display: 'flex' }}>
            <QuestionCard
              option={option}
              answeredValue={answeredValue}
              submitQuestion={submitQuestion}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        sx={{
          mt: 1,
          alignItems: 'center',
          justifyContent: 'space-around',
          display: { xs: 'flex', lg: 'none' },
        }}
      >
        <PrevArrow onClick={() => swiperRef.current?.swiper.slidePrev()} />
        <NextArrow onClick={() => swiperRef.current?.swiper.slideNext()} />
      </Box>
    </Container>
  )
}

interface QuestionCardProps {
  option: LifemapLevel
  answeredValue: QuestionValue | null
  submitQuestion: (value: QuestionValue) => void
}

function QuestionCard({
  option,
  answeredValue,
  submitQuestion,
}: QuestionCardProps) {
  const [hasImagedLoaded, setHasImagedLoaded] = useState(false)
  const [showIconAtColor, setShowIconAtColor] = useState<QuestionValue>(0)

  const { currentDirection } = useContext(MUIWrapperContext)

  const showCheckedColor =
    answeredValue === option.value || showIconAtColor === option.value

  useEffect(() => {
    setShowIconAtColor(0)
  }, [option])

  return (
    <Stack
      spacing={2}
      onClick={() => {
        submitQuestion(option.value)
      }}
      onMouseLeave={() => {
        setShowIconAtColor(0)
      }}
      onMouseEnter={() => {
        setShowIconAtColor(option.value)
      }}
      sx={{
        p: 2,
        flexGrow: 1,
        borderRadius: 2,
        cursor: 'pointer',
        backgroundColor: colorValues[option.value].bgColor,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!hasImagedLoaded && (
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ width: '100%', height: 'auto', aspectRatio: 1 }}
          />
        )}
        <CardMedia
          component="img"
          image={option.url}
          onLoad={() => {
            setHasImagedLoaded(true)
          }}
          style={{ display: hasImagedLoaded ? 'block' : 'none' }}
          sx={{
            borderRadius: 1,
            objectFit: 'cover',
            width: '100%',
            aspectRatio: 1,
          }}
        />

        <Grow timeout={250} in={showCheckedColor}>
          <Avatar
            sx={{
              width: 50,
              height: 50,
              bottom: -20,
              borderWidth: '2px',
              position: 'absolute',
              borderStyle: 'solid',
              bgcolor: colorValues[option.value].bgColor,
              borderColor: theme => theme.palette.primary.contrastText,
            }}
          >
            <Check />
          </Avatar>
        </Grow>
      </Box>

      <Box
        sx={{
          pt: 1,
          pb: 2,
          color: 'white',
          px: { xs: 1, sm: 2 },
          position: 'relative',
        }}
      >
        <Typography
          sx={{
            textAlign: currentDirection === 'ltr' ? 'center' : 'start',
            color: colorValues[option.value].textColor,
          }}
        >
          {option.description}
        </Typography>
      </Box>
    </Stack>
  )
}

interface PrevArrowProps {
  onClick: () => void
}

function PrevArrow({ onClick }: PrevArrowProps) {
  return (
    <IconButton
      color="primary"
      className="swiper-button-prev"
      sx={{
        '&::after': {
          pr: 5,
          color: COLORS.GREEN,
        },
      }}
      onClick={() => {
        onClick()
      }}
    ></IconButton>
  )
}

interface NextArrowProps {
  onClick: () => void
}

function NextArrow({ onClick }: NextArrowProps) {
  const { t } = useTranslation()

  return (
    <Tooltip
      arrow
      followCursor
      placement="top"
      enterDelay={200}
      TransitionComponent={Zoom}
      title={
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {t('views.lifemap.indicatorSlide')}
        </Typography>
      }
    >
      <IconButton
        className="swiper-button-next"
        sx={{
          '&::after': {
            pl: 5,
            color: COLORS.GREEN,
          },
        }}
        color="primary"
        onClick={() => {
          onClick()
        }}
      ></IconButton>
    </Tooltip>
  )
}

interface CustomBulletProps {
  idx: number
  className: string
}

function CustomBullet({ idx, className }: CustomBulletProps) {
  return (
    <Box
      className={className}
      sx={{
        p: 0.75,
        backgroundColor: COLORS.LIGHT_GREY,
      }}
    ></Box>
  )
}
export default StopLightQuestionCarousel
