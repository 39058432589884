import imageCompression from 'browser-image-compression'

import { sanitizeDraft } from '~/utils/survey-utils'
import { type PlatformLanguage } from '~/utils/types/i18n'
import { type Role } from '~/utils/types/user'

import {
  type ServerResponse,
  axios,
  cleanAxios,
  mapToBackendLanguageWithFallback,
  normalizeToPrimaryLanguages,
} from './axios'
import {
  type FamilyByID,
  type FamilyMembersList,
} from './services/family-services'
import { type normalizeDraftForSnapshot } from './services/lifemap-survey-services'
import { type CurrentDraft } from './utils/types/current-draft'
import { type CurrentSurvey } from './utils/types/current-survey'
import { type Permission } from './utils/types/user'

const CancelToken = axios.CancelToken
export const source = CancelToken.source()
let filterSource = CancelToken.source()

export async function getSurveys() {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query { surveysByUser { title id createdAt description minimumPriorities privacyPolicy { title  text } termsConditions{ title text }  surveyConfig { documentType {text value otherOption } gender { text value otherOption } surveyLocation { country latitude longitude} }  surveyEconomicQuestions { questionText codeName answerType topic required forFamilyMember options {text value conditions{codeName, type, values, operator, valueType, showIfNoData}}, conditions{codeName, type, value, operator}, conditionGroups{groupOperator, joinNextGroup, conditions{codeName, type, value, operator}} } surveyStoplightQuestions { questionText definition codeName dimension id stoplightColors { url value description } required } disclaimer { title subtitle text } } }',
    }),
  })
}

export async function deleteSurvey(surveyDefinitionId: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteSurveyDefinition($surveyDefinition: Long ) {deleteSurveyDefinition(surveyDefinition: $surveyDefinition){successful}}',
      variables: {
        surveyDefinition: surveyDefinitionId,
      },
    }),
  })
}

export async function getSurveysByUser() {
  return await axios({
    method: 'post',
    url: '/graphql',
    cancelToken: filterSource.token,
    data: JSON.stringify({
      query:
        'query { surveysInfoWithOrgs { id, title, applications { id }, organizations { id } labels, stoplightType } }',
    }),
  })
}

export async function surveysByUserPaginated(filters, page: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query surveysByUserPaginated( $filter: String $country: String $page: Int $sortBy: String $sortDirection: String $lang: String) { surveysByUserPaginated( filter: $filter country: $country page: $page sortBy: $sortBy sortDirection: $sortDirection lang: $lang ) { content { id title indicatorsCount createdAt updatedAt status organizations { id name } applications { id name } assignedUsers { userId username role } locked lockedBy lockedByUserId } totalPages totalElements } }',
      variables: {
        ...filters,
        page,
      },
    }),
  })
}

export interface SurveyDefinitionByUserResponse {
  id: number
  title: string
  description: string
  indicatorsCount: number
  createdAt: string
}

export async function getSurveysDefinition() {
  const response = await axios.post<
    ServerResponse<{
      surveysDefinitionByUser: SurveyDefinitionByUserResponse[]
    }>
  >('/graphql', {
    query:
      'query { surveysDefinitionByUser {id,title,description,indicatorsCount, createdAt} }',
  })

  return response.data.data.surveysDefinitionByUser
}

export async function getSurveyById(surveyId: number, lang?: PlatformLanguage) {
  return await axios<ServerResponse<{ surveyById: CurrentSurvey }>>({
    method: 'post',
    url: '/graphql',
    headers: {
      ...(lang ? { 'X-locale': normalizeToPrimaryLanguages(lang) } : {}),
    },

    data: JSON.stringify({
      query: `query { surveyById(surveyId:${surveyId}) { customLabels {labelText labelType} lifemapQuestions{questionText questionType} title id createdAt status description minimumPriorities lang stoplightType privacyPolicy { title  text } termsConditions{ title text }  surveyConfig { disableMemberQuestions familyCodeQRSupport verifiedEmailRequired isDemo isSurveyAnonymous shouldCreateUserBeforeSurveyFlow documentType {text value otherOption } gender { text value otherOption } stoplightOptional signSupport pictureSupport surveyLocation { country latitude longitude} } surveyEconomicQuestions { questionText codeName answerType shortName topic topicAudio required forFamilyMember orderNumber introduction options {text value otherOption conditions{codeName, type, values, operator, valueType, showIfNoData}}, conditions{codeName, type, value, operator}, conditionGroups{groupOperator, joinNextGroup, conditions{codeName, type, value, operator}} } surveyStoplightQuestions { questionText definition shortName description codeName dimension id surveyStoplightDimension{id} surveyIndicator{codeName} questionAudio stoplightColors { url urlImage value description } required orderNumber reviewed status enableDoesNotApply } disclaimer { title subtitle text } } }`,
    }),
  })
}

export async function getIndicatorsBySurveyId(surveyId: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: `query { surveyById(surveyId:${surveyId}) { surveyStoplightQuestions { id codeName shortName } } }`,
    }),
  })
}

export function cancelFilterRequest() {
  filterSource.cancel('Operation canceled by the user.')

  filterSource = CancelToken.source()
}

export async function getFamilyImages(familyId: number) {
  return await axios<
    ServerResponse<{
      picturesSignaturesByFamily: Array<{
        category: 'picture' | 'signature'
        url: string
      }>
    }>
  >({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query picturesSignaturesByFamily($family: Long!) { picturesSignaturesByFamily (family: $family) {  category url } }',
      variables: {
        family: familyId,
      },
    }),
  })
}

export async function getFamilyNotes(familyId: number) {
  interface Note {
    id: number
    familyId: number
    note: string
    noteDate: number
    noteUser: string
  }

  return await axios<ServerResponse<{ notesByFamily: Note[] }>>({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: `query { notesByFamily (familyId: ${familyId}) { id, familyId, note, noteDate, noteUser } }`,
    }),
  })
}

export async function saveFamilyNote(familyId: number, familyNote) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation saveFamilyNote($familyNote: FamilyNoteDtoInput) {saveFamilyNote(familyNote: $familyNote) { id, familyId, note, noteDate, noteUser } }',
      variables: {
        familyNote: {
          note: familyNote,
          familyId,
        },
      },
    }),
  })
}

export async function editFamilyNote(
  noteId: number,
  familyId: number,
  familyNote,
) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation updateFamilyNote($familyNote: FamilyNoteDtoInput) { updateFamilyNote(familyNote: $familyNote) { note } }',
      variables: {
        familyNote: {
          id: noteId,
          familyId,
          note: familyNote,
        },
      },
    }),
  })
}

export async function deleteFamilyNote(noteId: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteFamilyNote($familyNoteId: Long) { deleteFamilyNote(familyNoteId: $familyNoteId) { successful } }',
      variables: {
        familyNoteId: noteId,
      },
    }),
  })
}

export async function deleteSnapshot(snapshot) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: `mutation deleteSnapshot($snapshot: Long!) { deleteSnapshot (snapshot: $snapshot) {successful infos} }`,
      variables: {
        snapshot,
      },
    }),
  })
}

export async function deleteDrafts(drafts) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: `mutation deleteDrafts($drafts: [String]) {deleteDrafts(drafts: $drafts)}`,
      variables: {
        drafts,
      },
    }),
  })
}

// submit a new snapshot/lifemap/draft
export async function submitDraft(snapshot, surveyLocation) {
  const sanitizedSnapshot = sanitizeDraft(snapshot, surveyLocation)

  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation addSnapshot($newSnapshot: NewSnapshotDTOInput) {addSnapshot(newSnapshot: $newSnapshot)  { surveyId  surveyVersionId snapshotId  snapshotStoplightAchievements { action indicator roadmap } snapshotStoplightPriorities { reason action indicator estimatedDate } family { familyId code name } user { userId  username } indicatorSurveyDataList {key value} economicSurveyDataList {key value} familyDataDTO { latitude longitude accuracy familyMemberDTOList { firstName lastName socioEconomicAnswers {key value} } } } }',
      variables: { newSnapshot: { ...sanitizedSnapshot } },
    }),
  })
}

const compressionOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
}

async function dataURItoBlob(dataURI: string) {
  return await new Promise((resolve, reject) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1])

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length)

    // create a view into the buffer
    const ia = new Uint8Array(ab)

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString })

    resolve(blob)
  })
}

export async function submitPictures(snapshot) {
  const formData = new FormData()

  const signProcess = async base64Sign => {
    const sign = await dataURItoBlob(base64Sign)
    // @ts-expect-error
    formData.append('sign', sign)
  }

  for (const pic of snapshot.pictures) {
    const image = await dataURItoBlob(pic.base64.content)
    // @ts-expect-error
    const compressedImage = await imageCompression(image, compressionOptions)
    formData.append('pictures', compressedImage)
  }

  !!snapshot.sign && (await signProcess(snapshot.sign))

  return await axios({
    method: 'post',
    url: '/api/v1/snapshots/files/pictures/upload',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
}

export async function resetPasswordService(formData: FormData) {
  return await axios({
    method: 'post',
    url: '/password/resetPassword',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    data: formData,
  })
}

interface ResetPasswordBody {
  userId: string
  token: string
  password: string
  repeatPassword: string
}
export async function resetPassword(resetPasswordBody: ResetPasswordBody) {
  return await axios({
    method: 'post',
    data: resetPasswordBody,
    url: '/password/changePasswordByToken',
  })
}

export async function resetPasswordBySMS(resetPasswordBySMSBody: FormData) {
  return await axios.post<{ userId: number }>(
    '/password/resetPasswordBySMS',
    resetPasswordBySMSBody,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    },
  )
}

interface ChangePasswordBySMSCodeBody {
  userId: number
  code: string
  password: string
  repeatPassword: string
  phoneNumber: string
}
export async function changePasswordBySMSCode(
  changePasswordBySmsBody: ChangePasswordBySMSCodeBody,
) {
  return await axios.post('/password/changePasswordBySMSCode', {
    ...changePasswordBySmsBody,
  })
}

export async function checkSessionToken(token: string) {
  return await cleanAxios({
    method: 'get',
    url: '/api/v1/users/validate',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export async function getOrganizationsByHub(hubID: number | null) {
  return await axios<
    ServerResponse<{ organizations: Array<{ id: number; name: string }> }>
  >({
    method: 'post',
    cancelToken: filterSource.token,
    url: '/graphql',
    data: JSON.stringify({
      query: `query { organizations (hub:${hubID}) {id, name} }`,
    }),
  })
}

export async function getOrganizationsByOrganization(organizationId: number) {
  return await axios({
    method: 'post',
    cancelToken: filterSource.token,
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query organizations($org: Long) { organizations(org: $org) { id name } }',
      variables: {
        org: organizationId,
      },
    }),
  })
}

export async function deleteOrganization(organizationId: number) {
  return await axios({
    method: 'delete',
    url: `/api/v1/organizations/${organizationId}`,
  })
}

export async function getOrganizationsWithoutInternvetions(hub: string) {
  return await axios({
    method: 'post',
    cancelToken: filterSource.token,
    url: '/graphql',
    data: JSON.stringify({
      query: `query { organizationsWithoutInterventions (hub:${hub}) {id, name} }`,
    }),
  })
}

export async function getOrganization(organizationId: number) {
  return await axios({
    method: 'get',
    url: `/api/v1/organizations/${organizationId}`,
  })
}

export async function getOrganizationTypes() {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: 'query { organizantionTypes {code, description } }',
    }),
  })
}

export async function getOrganizationAreaTypes() {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: 'query { organizationAreasTypes {code, description } }',
    }),
  })
}

export async function getOrganizationFinalUserTypes() {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: 'query { organizationFinalUserTypes {code, description } }',
    }),
  })
}

export async function getOrganizationEndSurveyTypesTypes() {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: 'query { organizationEndSurveyTypes {code, description } }',
    }),
  })
}

export async function getHubs() {
  interface HubsByUserResponse {
    id: number
    name: string
    description: string
    logoUrl: string | null
  }

  return await axios<ServerResponse<{ hubsByUser: HubsByUserResponse[] }>>({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: 'query { hubsByUser {id, name, description, logoUrl } }',
    }),
  })
}

export async function getHubsPaginated({
  page,
  pageSize,
  hubNameFilter,
  hubCountryFilter,
}: {
  page: number
  pageSize: number
  hubNameFilter: string
  hubCountryFilter: string
}) {
  const response = await axios<{
    totalPages: number
    totalRecords: number
    currentPage: number
    pageSize: number
    list: Array<{
      id: number
      name: string
      description: string
      logoUrl: string | null
      country: string | null
      file: string | null
      language: string
      partnerType: string
      labels: Array<string | null>
      interactiveHelp: null | unknown
      allowSolutions: boolean
      projectsSupport: boolean
      zoomLimit: boolean
      allowInterventions: boolean
      countryCode: string
      active: boolean
    }>
  }>({
    method: 'get',
    url: '/api/v1/applications',
    params: {
      page,
      per_page: pageSize,
      filter: hubNameFilter,
      countryCode: hubCountryFilter,
    },
  })

  return response.data
}

export async function addOrUpdateHub(hub) {
  if (!hub.id) {
    return await axios({
      method: 'post',
      url: '/api/v1/applications',
      data: hub,
    })
  }
  return await axios({
    method: 'put',
    url: `/api/v1/applications/${hub.id}`,
    data: hub,
  })
}

export async function deleteHub(hubId: number) {
  return await axios({
    method: 'delete',
    url: `/api/v1/applications/${hubId}`,
  })
}

export async function getDrafts() {
  interface SnapshotDraft {
    stoplightAlreadyAttached: boolean
    snapshotId: string
    snapshotDraftDate: string
    draftId: string
    lifemapNavHistory: Array<{
      url: string
      state: string
    }>
    surveyId: number
    project: string
    economicTime: string
    stoplightTime: string
    lifemapTime: string
    justStoplight: boolean
    surveyVersionId: number
    snapshotStoplightAchievements: Array<{
      action: string
      indicator: string
      roadmap: string
    }>
    snapshotStoplightPriorities: Array<{
      reason: string
      action: string
      indicator: string
      estimatedDate: string
    }>

    indicatorSurveyDataList: Array<{
      key: string
      value: string
    }>
    economicSurveyDataList: Array<{
      key: string
      value: string
      multipleValue: string
      other: string
    }>
    familyDataDTO: {
      familyId: number | null
      countFamilyMembers: number
      latitude: number
      longitude: number
      country: string
      familyMemberDTOList: Array<{
        firstParticipant: boolean
        firstName: string
        lastName: string
        birthCountry: string
        gender: string
        customGender: string
        birthDate: string
        documentType: string
        customDocumentType: string
        documentNumber: string
        email: string
        phoneCode: string
        phoneNumber: string
        socioEconomicAnswers: Array<{
          key: string
          value: string
          other: string
          multipleValue: string
        }>
      }>
    }
  }
  return await axios.post<
    ServerResponse<{ getSnapshotDraft: SnapshotDraft[] }>
  >(
    '/graphql',
    JSON.stringify({
      query:
        'query { getSnapshotDraft{ stoplightAlreadyAttached snapshotId snapshotDraftDate draftId lifemapNavHistory { url state } surveyId project economicTime stoplightTime lifemapTime justStoplight surveyVersionId snapshotStoplightAchievements { action indicator roadmap } snapshotStoplightPriorities { reason action indicator estimatedDate } indicatorSurveyDataList {key value} economicSurveyDataList {key value multipleValue other} familyDataDTO { familyId countFamilyMembers latitude longitude country familyMemberDTOList { firstParticipant firstName lastName birthCountry gender customGender birthDate documentType customDocumentType documentNumber email phoneCode phoneNumber socioEconomicAnswers {key value other multipleValue} } } } }',
    }),
  )
}

// Saves a draft
type NormalizedDraftForSnapshot = ReturnType<typeof normalizeDraftForSnapshot>
export async function saveDraft(draft: NormalizedDraftForSnapshot) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation addSnapshotDraft($newSnapshot: NewSnapshotDTOInput) {addSnapshotDraft(newSnapshot: $newSnapshot)} ',
      variables: { newSnapshot: draft },
    }),
  })
}

export async function deleteDraft(draftId: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteDraft($draftId: String) {deleteDraft(draftId: $draftId)} ',
      variables: { draftId },
    }),
  })
}

export async function migrateFamilies(
  families,
  organization,
  facilitator,
  project,
) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation migrateFamilies($families: [FamilyModelInput], $organization: Long, $user: Long, $project: Long ) { migrateFamilies(families: $families, organization: $organization, user: $user, project: $project) {successful errors} }',
      variables: {
        families,
        organization,
        user: facilitator,
        project,
      },
    }),
  })
}

export async function mentorsByOrganizations(
  organizations: number[],
  hub: number | null,
) {
  interface MentorsByOrganization {
    id: number
    username: string
  }

  return await axios.post<
    ServerResponse<{ mentorsByOrganizations: MentorsByOrganization[] }>
  >('/graphql', {
    query:
      'query mentorsByOrganizations($organizations: [Long], $hub: Long) {mentorsByOrganizations(organizations: $organizations, hub: $hub) { id username }}',
    variables: {
      organizations,
      hub,
    },
  })
}

export async function assignFacilitator(familyId: number, mentorId: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation updateMentor($familyId: Long, $mentorId: Long) ' +
        '{  updateMentor(familyId: $familyId, mentorId: $mentorId) {    familyId    name    user { userId username role    }  }}',
      variables: {
        familyId,
        mentorId,
      },
    }),
  })
}

export async function updateFamilyProject({
  familyId,
  snapshotId,
  projectId,
}: {
  familyId: number
  snapshotId: number
  projectId: number
}) {
  const query = familyId
    ? 'mutation updateFamilyProject($familyId: Long, $projectId: Long) { updateFamilyProject(familyId: $familyId, projectId: $projectId) { familyId name user { userId username role } } }'
    : 'mutation updateSnapshotProject($snapshotId: Long, $projectId: Long) { updateSnapshotProject(snapshotId: $snapshotId, projectId: $projectId) { successful } }'
  const variables = {
    ...(familyId ? { familyId } : { snapshotId }),
    projectId,
  }

  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query,
      variables,
    }),
  })
}

export async function getPrioritiesAchievementByFamily(familyId: number) {
  return await axios<
    ServerResponse<{
      prioritiesAchievementsByFamily: {
        priorities: null | unknown[]
        achievements: null | unknown[]
      }
    }>
  >({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query prioritiesAchievementsByFamily($familyId: Long!) { prioritiesAchievementsByFamily (familyId: $familyId) { priorities {id updatedAt, color, indicator, reviewDate, reason, action, months, snapshotStoplightId} achievements {id indicator action roadmap snapshotStoplightId} } }',
      variables: {
        familyId,
      },
    }),
  })
}

export async function getPrioritiesAchievementsBySnapshot(snapshotId: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query prioritiesAchievementsBySnapshot($snapshotId: Long!) { prioritiesAchievementsBySnapshot (snapshotId: $snapshotId) { priorities {updatedAt, color, indicator, reviewDate, reason, action, months, snapshotStoplightId} achievements {indicator action roadmap} } }',
      variables: {
        snapshotId,
      },
    }),
  })
}

export async function addPriority(
  reason,
  action,
  months,
  snapshotStoplightId: number,
) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation addPriority($newPriority : PriorityDtoInput, $client : String) {addPriority(newPriority: $newPriority, client: $client)  {  indicator, reviewDate, reason, action, indicator, months, snapshotStoplightId } }',
      variables: {
        newPriority: {
          reason,
          action,
          months,
          snapshotStoplightId,
        },
        client: 'WEB',
      },
    }),
  })
}

export async function editPriority(id: number, reason, action, months) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation updateSnapshotStoplightPriority($priority: PriorityDtoInput) {updateSnapshotStoplightPriority(priority: $priority){successful}}',
      variables: {
        priority: {
          id,
          reason,
          action,
          months,
        },
      },
    }),
  })
}

export async function addAchievement(
  action,
  roadmap,
  snapshotStoplightId: number,
) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation addAchievementWeb($achievement: AchievementDtoInput) {addAchievementWeb (achievement: $achievement){successful}}',
      variables: {
        achievement: {
          action,
          roadmap,
          snapshotStoplightId,
        },
        client: 'WEB',
      },
    }),
  })
}

export async function editAchievement(id: number, action, roadmap) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation updateAchievement($achievement: AchievementDtoInput) {updateAchievement(achievement: $achievement){successful}}',
      variables: {
        achievement: {
          id,
          action,
          roadmap,
        },
      },
    }),
  })
}

export async function getLastSnapshot(familyId: number) {
  interface LastSnapshot {
    snapshotId: string
    previousIndicatorSurveyDataList: Array<{ key: string; value: string }>
    snapshotStoplightPriorities: Array<{ indicator: string }>
    snapshotStoplightAchievements: Array<{ indicator: string }>
    family: {
      countFamilyMembers: number
      latitude: number
      longitude: number
      familyMemberDTOList: FamilyMembersList[]
    }
  }
  return await axios<ServerResponse<{ getLastSnapshot: LastSnapshot }>>({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query getLastSnapshot($familyId: Long!) { getLastSnapshot (familyId: $familyId) { snapshotId previousIndicatorSurveyDataList {key value} snapshotStoplightPriorities{ indicator} snapshotStoplightAchievements{ indicator action roadmap} family { countFamilyMembers latitude longitude familyMemberDTOList { firstParticipant firstName  lastName birthCountry  gender customGender birthDate documentType customDocumentType documentNumber email phoneCode phoneNumber socioEconomicAnswers {key value other multipleValue} } } } }',
      variables: {
        familyId,
      },
    }),
  })
}

export async function sendLifemapPdfv2(
  snapshotId: number,
  lang: PlatformLanguage,
  email?,
) {
  return await axios({
    method: 'post',
    url: '/api/v1/reports/lifemap/email',
    headers: {
      'X-locale': normalizeToPrimaryLanguages(lang),
    },
    params: {
      snapshotId,
      email,
    },
  })
}

export async function downloadPdf(snapshotId: number, lang: PlatformLanguage) {
  return await axios({
    method: 'get',
    url: '/api/v1/reports/lifemap/pdf',
    headers: {
      'X-locale': normalizeToPrimaryLanguages(lang),
    },
    params: {
      snapshotId,
    },
    responseType: 'arraybuffer',
  })
}

/**
 *
 * @param snapshotId - The id of the snapshot to be used for the message
 * @param whatsappNumber - The whatsapp number. Should be in international format (e.g: +595123456789)
 * @returns
 */
export async function sendWhatsappMessage(
  snapshotId: number,
  whatsappNumber?: string,
) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation lifemapWhatsappNotification($notificationParameters: NotificationParametersInput) { lifemapWhatsappNotification(notificationParameters: $notificationParameters){status} }',
      variables: {
        notificationParameters: {
          snapshotId,
          phoneNumber: whatsappNumber,
        },
      },
    }),
  })
}

export async function assignOrganizations(
  organizations,
  applications,
  surveyId: number,
) {
  return await axios({
    method: 'put',
    url: `/api/v1/surveys/${surveyId}`,
    data: {
      applications,
      organizations,
    },
  })
}

export async function addOrUpdateOrg(org) {
  if (!org.id) {
    return await axios({
      method: 'post',
      url: '/api/v1/organizations',
      data: org,
    })
  }
  return await axios({
    method: 'put',
    url: `/api/v1/organizations/${org.id}`,
    data: org,
  })
}

export async function getUserById(userId: number) {
  interface RetrieveUserResponse {
    retrieveUser: {
      id: number
      name: string
      email: string
      hubName: string
      username: string
      organizationName: string

      hubModel: null | {
        id: number
        name: string
        allowSolutions: boolean
        interactiveHelp: boolean
        projectsSupport: boolean
        allowInterventions: boolean
      }
      organizationModel: {
        id: number
        name: string
        solutionsAccess: string
        projectsAccess: boolean
        solutionsAllowedFacilitators: boolean
      }
      role: string
      active: boolean
      permissions: Permission[]
      passExpireException: boolean
      projects: Array<{ title: string; id: number }>
      stakeholderModel: Record<string, unknown> | null
    }
  }

  return await axios.post<ServerResponse<RetrieveUserResponse>>('/graphql', {
    query:
      'query retrieveUser ($userId: Long) { retrieveUser (userId: $userId) {id, username, name, email, organizationName, hubName, hubModel{id name  interactiveHelp allowSolutions projectsSupport allowInterventions} organizationModel{id, name solutionsAccess  projectsAccess solutionsAllowedFacilitators} stakeholderModel{id name} role, active, passExpireException, projects {id title description} permissions } }',
    variables: {
      userId,
    },
  })
}

export async function addOrUpdateProject(values) {
  if (!values.id) {
    return await axios({
      method: 'post',
      url: '/graphql',
      data: JSON.stringify({
        query: `mutation createProject($project: ProjectModelInput) {createProject(project: $project){title}}`,
        variables: {
          project: {
            title: values.title,
            description: values.description,
            active: values.active,
            color: values.color,
          },
        },
      }),
    })
  }
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: `mutation updateProject($project: ProjectModelInput) {updateProject(project: $project){title}}`,
      variables: {
        project: {
          id: values.id,
          title: values.title,
          description: values.description,
          active: values.active,
          color: values.color,
        },
      },
    }),
  })
}

export interface ProjectsByOrganizationResponse {
  id: number
  title: string
  description: string
  color: string | null
  active: boolean
}

export async function getProjectsByOrganization(
  orgsId: number | number[],
  hubId?: number | null,
) {
  return await axios.post<
    ServerResponse<{ projectsByOrganization: ProjectsByOrganizationResponse[] }>
  >(
    '/graphql',
    JSON.stringify({
      query:
        'query projectsByOrganization($organizations: [Long], $hub: Long) { projectsByOrganization (organizations: $organizations, hub: $hub) { id, title, description, color, active} }',
      variables: {
        organizations: orgsId,
        hub: hubId,
      },
    }),
  )
}

export async function getProjectsPaginated({
  page,
  filter,
  organizations,
  sortBy,
  sortDirection,
}) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query searchProjects($page: Int, $filter: String, $organizations: [Long], $sortBy: String, $sortDirection: String) { searchProjects (page: $page, filter: $filter, organizations: $organizations, sortBy:$sortBy, sortDirection:$sortDirection) {content { id, title, description, active, color}, totalElements totalPages } }',
      variables: {
        page,
        filter,
        organizations,
        sortBy,
        sortDirection,
      },
    }),
  })
}

export async function deleteProject(projectId: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteProject($project: ProjectModelInput) {deleteProject(project: $project){successful} }',
      variables: { project: { id: projectId } },
    }),
  })
}

export async function getUsersPaginated(
  page: number,
  filter,
  organizations,
  hubs,
  role,
  sortBy,
  sortDirection,
) {
  interface SearchUsersResponse {
    id: number
    role: Role
    name: string
    email: string
    hubName: string
    active: boolean
    username: string
    organizationName: string
    permissions: string[] | null
    stakeholderName: unknown | null
    projects: Array<{ title: string }>
    hubModel: {
      id: number
      name: string
    }
    organizationModel: {
      id: number
      name: string
    }
  }

  interface SearchUsersResponseContent {
    content: SearchUsersResponse[]
    totalElements: number
  }

  return await axios.post<
    ServerResponse<{ searchUsers: SearchUsersResponseContent }>
  >('/graphql', {
    query:
      'query searchUsers( $page: Int $filter: String $organizations: [Long] $hubs: [Long] $role: String $sortBy: String $sortDirection: String ) { searchUsers( page: $page filter: $filter organizations: $organizations hubs: $hubs role: $role sortBy: $sortBy sortDirection: $sortDirection ) { content { projects { title } id username name email phoneNumber role hubName organizationName hubModel { id name } organizationModel { id name } stakeholderName active permissions } totalElements } }',
    variables: {
      page,
      filter,
      organizations,
      hubs,
      role,
      sortBy,
      sortDirection,
    },
  })
}

export async function downloadUserReport(filter, organizations, hubs, role) {
  return await axios({
    method: 'post',
    url: '/api/v1/users/reportXslx',
    data: JSON.stringify({
      filter,
      organizations,
      hubs,
      role,
    }),
    responseType: 'arraybuffer',
  })
}

export async function updateUser(values: Record<string, unknown>) {
  interface UpdateUserResponse {
    username: string
  }

  return await axios.post<ServerResponse<{ updateUser: UpdateUserResponse }>>(
    '/graphql',
    {
      query: `mutation updateUser($user: UserModelInput) {updateUser(user: $user){username} }`,
      variables: {
        user: {
          id: values.id,
          name: values.name,
          email: values.email,
          active: values.active,
          projects: values.projects,
          permissions: values.permissions,
          passExpireException: values.passExpireException,
        },
      },
    },
  )
}

export async function createUser(values: Record<string, unknown>) {
  return await axios.post<ServerResponse<unknown>>('/graphql', {
    query: `mutation createUser($user: UserModelInput) {createUser(user: $user){username} }`,
    variables: {
      user: values,
    },
  })
}

/**
 * @deprecated use `updatedUser` instead for updating users and `createUser` for creating them.
 */
export async function addOrUpdateUser(values) {
  if (!values.id) {
    return await axios({
      method: 'post',
      url: '/graphql',
      data: JSON.stringify({
        query: `mutation createUser($user: UserModelInput) {createUser(user: $user){username} }`,
        variables: {
          user: values,
        },
      }),
    })
  }
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: `mutation updateUser($user: UserModelInput) {updateUser(user: $user){username} }`,
      variables: {
        user: {
          id: values.id,
          name: values.name,
          email: values.email,
          active: values.active,
          projects: values.projects,
          permissions: values.permissions,
          passExpireException: values.passExpireException,
        },
      },
    }),
  })
}

export async function checkUserName(username: string) {
  return await axios.get<boolean>(
    `/api/v1/users/check-username?username=${username}`,
  )
}

export async function searchRecords(filters) {
  return await axios.post<
    ServerResponse<{
      searchSnapshots: {
        totalElements: number
        content: unknown
        additionalData?: {
          distinctFamilies: number
        }
      }
    }>
  >('/graphql', {
    query:
      'query searchSnapshots( $surveyDefinition: Long!, $hubs: [Long], $orgs: [Long], $fromDate: Long, $toDate: Long, $followupSurveys: Boolean, $surveyUsers: [Long], $projects: [Long], $stoplightFilters: [StoplightFilterInput],  $page: Int  $sortBy: String, $sortDirection: String) { searchSnapshots ( surveyDefinition: $surveyDefinition, hubs: $hubs, orgs: $orgs, fromDate: $fromDate, toDate: $toDate, followupSurveys: $followupSurveys, surveyUsers: $surveyUsers, projects: $projects, stoplightFilters: $stoplightFilters, page: $page, sortBy: $sortBy, sortDirection: $sortDirection) { page totalElements totalPages additionalData content {family familyName familyCode surveyDate surveyNumber }  } }',
    variables: {
      surveyDefinition: filters.survey.value,
      hubs: filters.hubs,
      orgs: filters.orgs,
      projects: filters.projects,
      fromDate: filters.fromDate,
      toDate: filters.toDate,
      followupSurveys: filters.includeRetake,
      surveyUsers: filters.surveyUsers,
      stoplightFilters: filters.stoplightFilters,
      page: filters.page,
      sortBy: filters.sortBy,
      sortDirection: filters.orderDirection,
    },
  })
}

export async function deleteUser(userId: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteUser($user: Long!) { deleteUser (user: $user) { successful } }',
      variables: {
        user: userId,
      },
    }),
  })
}

export async function generateReport(filters, sheets) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation generateSnapshotReport($filters: SnapshotFiltersInput, $sheets: [String]) { generateSnapshotReport (filters: $filters, sheets: $sheets) { successful } }',
      variables: {
        filters: {
          surveyDefinition: filters.survey.value,
          hubs: filters.hubs,
          orgs: filters.orgs,
          fromDate: filters.fromDate,
          toDate: filters.toDate,
          followupSurveys: filters.includeRetake,
          surveyUsers: filters.surveyUsers,
          projects: filters.projects,
          stoplightFilters: filters.stoplightFilters,
          page: filters.page,
          sortBy: filters.sortBy,
          sortDirection: filters.orderDirection,
          reportDate: filters.reportDate,
        },
        sheets,
      },
    }),
  })
}

export async function listReportsByUser(page: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query reportsGenerationByUser($page: Int, $sortBy: String, $sortDirection: String) { reportsGenerationByUser(page: $page, sortBy: $sortBy, sortDirection: $sortDirection) {content {surveyDefinitionTitle username status reportUrl requestedAt generatedAt reportDate} totalElements } }',
      variables: {
        page,
        sortBy: 'requestedAt',
        sortDirection: 'asc',
      },
    }),
  })
}

export async function getCollectionTypes(lang: PlatformLanguage) {
  return await axios({
    method: 'post',
    url: '/graphql',
    headers: {
      'X-locale': normalizeToPrimaryLanguages(lang),
    },
    data: JSON.stringify({
      query: 'query { listArticlesTypes { code description } }',
    }),
  })
}

export async function getArticleById(
  id: number,
  collection,
  section,
  language: PlatformLanguage,
) {
  return await axios({
    method: 'post',
    url: '/graphql',
    headers: {
      'X-locale': normalizeToPrimaryLanguages(language),
    },
    data: JSON.stringify({
      query:
        'query getArticleById($id: Long, $collection: String, $section: String, $language: String) { getArticleById(id: $id, collection: $collection, section: $section, language: $language) { id title description contentRich contentText collection lang published createdAt updatedAt section} }',
      variables: {
        id,
        collection,
        section,
        language: language ? normalizeToPrimaryLanguages(language) : '',
      },
    }),
  })
}

export async function deleteArticleById(id: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteArticle($article: HelpArticleModelInput) {deleteArticle (article: $article){ successful }}',
      variables: {
        article: {
          id,
        },
      },
    }),
  })
}

export async function reOrderArticles(articles) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query reOrderArticles($articles: [HelpArticleModelInput]) { reOrderArticles(articles: $articles) { id title description published createdAt collection orderNumber}}',
      variables: {
        articles,
      },
    }),
  })
}

export async function saveOrUpdateArticle(values) {
  if (!values.id) {
    return await axios({
      method: 'post',
      url: '/graphql',
      data: JSON.stringify({
        query: `mutation createArticle($article: HelpArticleModelInput) {createArticle (article: $article){ id }}`,
        variables: {
          article: {
            title: values.title,
            description: values.subtitle,
            collection: values.collection.value,
            lang: mapToBackendLanguageWithFallback(values.language),
            contentText: values.contentText,
            contentRich: values.contentRich,
            published: values.published,
            section: values.section,
            orderNumber: values.orderNumber,
          },
        },
      }),
    })
  }
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: `mutation updateArticle($article: HelpArticleModelInput) {updateArticle (article: $article){ successful }}`,
      variables: {
        article: {
          id: values.id,
          title: values.title,
          description: values.subtitle,
          collection: values.collection.value,
          lang: mapToBackendLanguageWithFallback(values.language),
          contentText: values.contentText,
          contentRich: values.contentRich,
          published: values.published,
          section: values.section,
        },
      },
    }),
  })
}

export async function getDimensionsByUser(lang: PlatformLanguage) {
  return await axios.post<
    ServerResponse<{
      getDimensions: Array<{ name: string; surveyDimensionId: number }>
    }>
  >(
    '/graphql',
    JSON.stringify({
      query: 'query { getDimensions { name, surveyDimensionId } }',
    }),
    {
      headers: {
        'X-locale': normalizeToPrimaryLanguages(lang),
      },
    },
  )
}

export async function getIndicatorsByDimensions(
  dimensions: string[],
  lang: PlatformLanguage,
) {
  return await axios.post<
    ServerResponse<{
      indicatorsByUserAndDimensions: Array<{
        codeName: string
        name: string
        surveyIndicatorId: number
      }>
    }>
  >(
    '/graphql',
    JSON.stringify({
      query:
        'query indicatorsByUserAndDimensions( $dimensions: [String] ) { indicatorsByUserAndDimensions (dimensions: $dimensions) { codeName, name, surveyIndicatorId  } }',
      variables: {
        dimensions,
      },
    }),
    { headers: { 'X-locale': normalizeToPrimaryLanguages(lang) } },
  )
}

export async function submitResources(resources) {
  const formData = new FormData()

  for (const resource of resources) {
    if (resource.type === 'image/jpeg' || resource.type === 'image/png') {
      const compressedImage = await imageCompression(
        resource,
        compressionOptions,
      )
      const file = new File([compressedImage], resource.name, {
        type: resource.type,
      })
      formData.append('resources', file)
    } else {
      formData.append('resources', resource)
    }
  }

  return await axios({
    method: 'post',
    url: '/api/v1/solutions/resources/store',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
}

export async function getArticles(
  filter: string,
  collection,
  lang: PlatformLanguage,
  tags,
) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query listArticles($filter: String, $collection: String, $lang: String, $tags: [String]) { listArticles(filter: $filter, collection: $collection, lang: $lang, tags: $tags) { id title description published createdAt updatedAt collection orderNumber ratingPositive ratingNegative} }',
      variables: {
        filter,
        collection,
        lang: normalizeToPrimaryLanguages(lang),
        tags,
      },
    }),
  })
}

export async function getRatedArticlesByUser() {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: 'query  {retrieveRatedArticlesByUser { id rating}}',
    }),
  })
}

export async function addRatingToArticle(
  articleId: number,
  rating: 'negative' | 'positive',
) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation addRatingToArticle($rating: String, $article: Long) {addRatingToArticle(rating: $rating,article:$article)  { successful  }}',
      variables: {
        rating,
        article: articleId,
      },
    }),
  })
}

export async function updateFamilyDetails(familyId: number, familyDetails) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation updateFamilyDetails($family: FamilyModelInput) {updateFamilyDetails(family: $family){successful}}',
      variables: {
        family: {
          familyId,
          familyMembersList: familyDetails,
        },
      },
    }),
  })
}

export async function updateEconomicData(id: number, draft) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation updateSnapshot($snapshot: SnapshotUpdateModelInput) {updateSnapshot(snapshot: $snapshot){successful}}',
      variables: {
        snapshot: {
          id,
          ...draft,
        },
      },
    }),
  })
}

export async function updateLocation(
  familyId: number,
  lat: string,
  lng: string,
) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation updateFamilyLocation($familyLocation: FamilyLocationModelInput) {updateFamilyLocation(familyLocation: $familyLocation){successful}}',
      variables: {
        familyLocation: {
          family: familyId,
          latitude: lat,
          longitude: lng,
        },
      },
    }),
  })
}

// submit files
export async function savePictures(images) {
  const formData = new FormData()

  for (const image of images) {
    const compressedImage = await imageCompression(image, compressionOptions)
    formData.append('pictures', compressedImage)
  }

  return await axios({
    method: 'post',
    url: '/api/v1/snapshots/files/pictures/upload',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
}

export async function updateFamilyImages(snapshotId: number, pictures) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation updateSnapshotPictures($snapshot: SnapshotUpdateModelInput) {updateSnapshotPictures(snapshot: $snapshot){successful}}',
      variables: {
        snapshot: {
          id: snapshotId,
          pictures,
        },
      },
    }),
  })
}

export async function saveSign(base64Sign) {
  const formData = new FormData()

  const signProcess = async base64Sign => {
    const sign = await dataURItoBlob(base64Sign)
    // @ts-expect-error
    formData.append('sign', sign)
  }

  await signProcess(base64Sign)

  return await axios({
    method: 'post',
    url: '/api/v1/snapshots/files/pictures/upload',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
}

export async function updateSign(id: number, sign) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation updateSnapshotSign($snapshot: SnapshotUpdateModelInput) {updateSnapshotSign(snapshot: $snapshot){successful}}',
      variables: {
        snapshot: {
          id,
          sign,
        },
      },
    }),
  })
}

export async function familyUserData() {
  interface FamilyUserDataResponse extends FamilyByID {
    profilePictureUrl: string | null
  }

  return await axios.post<
    ServerResponse<{ familyUserData: FamilyUserDataResponse }>
  >(
    '/graphql',
    JSON.stringify({
      query:
        'query { familyUserData {user {userId username} familyId name code latitude longitude numberOfSnapshots lastSnapshot profilePictureUrl allowRetake organization { id, name } country{country} project {id title}familyMemberDTOList { memberIdentifier firstParticipant firstName lastName gender genderText customGender birthDate documentType documentTypeText customDocumentType documentNumber birthCountry email phoneNumber phoneCode} snapshotEconomics { codeName value multipleValueArray questionText text multipleText multipleTextArray other topic} membersEconomic{ memberIdentifier firstName economic{codeName value multipleValue multipleValueArray questionText text multipleText multipleTextArray other topic} } snapshotIndicators{ createdAt  stoplightSkipped surveyId indicatorSurveyDataList{value shortName dimension key snapshotStoplightId} priorities{key} achievements{key} countRedIndicators countYellowIndicators countGreenIndicators countSkippedIndicators countIndicatorsAchievements countIndicatorsPriorities indicatorsPriorities{indicator}} } }',
    }),
  )
}

export async function attachSnapshotStoplight(draft: CurrentDraft) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation attachSnapshotStoplight($snapshot: SnapshotUpdateModelInput) {attachSnapshotStoplight(snapshot: $snapshot){successful}}',
      variables: {
        snapshot: {
          id: draft.snapshotId,
          draftId: draft.draftId,
          indicatorSurveyDataList: draft.indicatorSurveyDataList,
          priorities: draft.priorities,
          achievements: draft.achievements,
          economicTime: draft.economicTime,
          stoplightTime: draft.stoplightTime,
          lifemapTime: draft.lifemapTime,
        },
      },
    }),
  })
}

export async function updateFamilyProfilePicture(
  familyId: number,
  familyProfilePicture: string,
) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation updateFamilyProfilePicture($familyId: Long, $familyProfilePicture: String) ' +
        '{  updateFamilyProfilePicture(familyId: $familyId, familyProfilePicture: $familyProfilePicture) { familyId name } }',
      variables: {
        familyId,
        familyProfilePicture,
      },
    }),
  })
}

export async function picturesSignaturesBySnapshot(snapshotId: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query picturesSignaturesBySnapshot($snapshotId: Long!) { picturesSignaturesBySnapshot (snapshotId: $snapshotId) { category url } }',
      variables: {
        snapshotId,
      },
    }),
  })
}

export async function deletePriority(id: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteSnapshotStoplightPriority($priority: PriorityDtoInput) {deleteSnapshotStoplightPriority(priority: $priority){successful}}',
      variables: {
        priority: {
          id,
        },
      },
    }),
  })
}

export async function deleteAchievement(id: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteAchievement($achievement: AchievementDtoInput) {deleteAchievement(achievement: $achievement){successful}}',
      variables: {
        achievement: {
          id,
        },
      },
    }),
  })
}

export interface OfflineMap {
  center: [number, number]
  from: [number, number]
  id: number
  name: string
  to: [number, number]
}

export async function listOfflineMaps(organizationId: number) {
  return await axios<ServerResponse<{ searchOfflineMaps: OfflineMap[] }>>({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query searchOfflineMaps($organization: Long) {searchOfflineMaps(organization: $organization) { id, name, from, to, center }}',
      variables: {
        organization: organizationId,
      },
    }),
  })
}

export async function addOrUpdateOfflineMap(surveyOfflineMap) {
  if (!surveyOfflineMap.id) {
    return await axios({
      method: 'post',
      url: '/graphql',
      data: JSON.stringify({
        query:
          'mutation addSurveyOfflineMap($surveyOfflineMap: SurveyOfflineMapInput) {addSurveyOfflineMap (surveyOfflineMap: $surveyOfflineMap){successful}}',
        variables: {
          surveyOfflineMap,
        },
      }),
    })
  }
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation updateSurveyOfflineMap($surveyOfflineMap: SurveyOfflineMapInput) {updateSurveyOfflineMap (surveyOfflineMap: $surveyOfflineMap){successful}}',
      variables: {
        surveyOfflineMap,
      },
    }),
  })
}

export async function deleteMap(surveyOfflineMap) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteSurveyOfflineMap($surveyOfflineMap: Long) {deleteSurveyOfflineMap(surveyOfflineMap: $surveyOfflineMap){successful}}',
      variables: {
        surveyOfflineMap,
      },
    }),
  })
}

export async function supportedLanguages() {
  type Response = ServerResponse<{
    supportedLanguages: Array<{ code: string; description: string }>
  }>

  return await axios.post<Response>(
    '/graphql',
    JSON.stringify({
      query: 'query { supportedLanguages {code, description } }',
    }),
  )
}

export async function economicLibraryPool(
  language: PlatformLanguage,
  stoplightType,
  filter: string,
) {
  return await axios({
    method: 'post',
    url: '/graphql',
    headers: {
      'X-locale': language,
    },
    data: JSON.stringify({
      query:
        'query listEconomicQuestion($language: String, $stoplightType: String, $filter: String) {listEconomicQuestion(language: $language, stoplightType: $stoplightType,  filter: $filter)  { id, codeName,questionText,topic, topicInfo, answerType,shortName, options} }',
      variables: {
        language,
        stoplightType,
        filter,
      },
    }),
  })
}

export async function unifyFamilies(families) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation unifyFamilies($families: [Long]) { unifyFamilies (families: $families) { successful } }',
      variables: {
        families,
      },
    }),
  })
}

export async function uploadAudio(audio) {
  const formData = new FormData()
  formData.append('upload', audio)
  return await axios({
    method: 'post',
    url: '/api/v1/surveys/audio/upload',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
}

/**
 * @deprecated Use createSurveyDefinition or updateSurveyDefinition instead (You'll find them in the survey-builder file).
 */
export async function createorUpdateSurveyDefinition(survey) {
  if (survey.surveyDefinition.id) {
    return await axios({
      method: 'post',
      url: '/graphql',
      data: JSON.stringify({
        query:
          'mutation updateSurveyDefinitionFromBuilder($privacyPolicy: TermCondPolDTOInput, $termCond : TermCondPolDTOInput, $surveyDefinition : SurveyDefinitionModelInput, $economicQuestions:[SurveyEconomicModelInput], $surveyConfig:SurveyConfigInput, $stoplightIndicators:[SurveyStoplightModelInput]) {updateSurveyDefinitionFromBuilder (privacyPolicy: $privacyPolicy, termCond:$termCond, surveyDefinition:$surveyDefinition, economicQuestions:$economicQuestions, surveyConfig:$surveyConfig, stoplightIndicators:$stoplightIndicators) {successful}}',
        variables: {
          ...survey,
        },
      }),
    })
  }
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation createSurveyDefinition($privacyPolicy: TermCondPolDTOInput, $termCond : TermCondPolDTOInput, $surveyDefinition : SurveyDefinitionModelInput, $application: Long) { createSurveyDefinition (privacyPolicy: $privacyPolicy, termCond:$termCond, surveyDefinition:$surveyDefinition, application:$application) { id, title, description, countryCode,latitude,longitude,lang, privacyPolicy{title,text}, termsConditions{title,text}}  }',
      variables: {
        ...survey,
      },
    }),
  })
}

export interface LibraryOptions {
  indicators: Array<{ code: string; description: string }>
  measurementUnits: Array<{ code: string; description: string }>
  stoplights: Array<{ code: string; description: string }>
  targets: Array<{ code: string; description: string }>
  zones: Array<{ code: string; description: string }>
}
export async function getLibraryFilterOptions() {
  return await axios<ServerResponse<{ listLibraryOptions: LibraryOptions }>>({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: 'query { listLibraryOptions }',
    }),
  })
}

export async function getListLibraryIndicators(filters) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query listStoplightIndicatorsBy($lang: String, $status : String, $country: String, $measurementUnit: String, $zones: [String], $targets: [String], $filter: String, $dimensionId:Long, $tag:String,$verified:Boolean $page:Int, $generic: Boolean, $stoplightType: String) {listStoplightIndicatorsBy(lang: $lang, status: $status, country: $country, measurementUnit: $measurementUnit, zones:$zones, targets:$targets, filter: $filter, dimensionId:$dimensionId,tag:$tag,verified:$verified, generic: $generic, page:$page, stoplightType:$stoplightType)  { totalPages content{ id lang country questionText shortName description verified  definition codeName, zones, targets measurementUnit tag stoplightType status updatedAt generic stoplightDimension{surveyDimensionId name} stoplightColors{id url value description }}}}',
      variables: {
        ...filters,
      },
    }),
  })
}

export async function createOrUpdateLibraryIndicator(
  stoplightIndicator,
  stoplightId: number | null = null,
) {
  const operation = stoplightIndicator.id
    ? 'updateStoplightIndicator'
    : 'createStoplightIndicator'
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: `mutation ${operation}($stoplightIndicator: StoplightIndicatorModelInput, $stoplightId: Long) { ${operation} (stoplightIndicator: $stoplightIndicator, stoplightId: $stoplightId ) { successful } }`,
      variables: {
        stoplightIndicator,
        stoplightId,
      },
    }),
  })
}

export async function verifiedCodenames(onlyWithTranslations: boolean) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query allVerifiedCodeNames($onlyWithTranslations: Boolean) { allVerifiedCodeNames(onlyWithTranslations: $onlyWithTranslations)}',
      variables: {
        onlyWithTranslations,
      },
    }),
  })
}

export async function deleteLibraryIndicator(id: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteStoplightIndicator($id:Long) {deleteStoplightIndicator(id: $id){successful}}',
      variables: {
        id,
      },
    }),
  })
}

export async function migrateSurvey(id: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation migrateSurveyDefinitionToDynamo($id: Long) {migrateSurveyDefinitionToDynamo(id: $id){successful}}',
      variables: {
        id,
      },
    }),
  })
}

export async function updateIndicatorColor(snapshotId: number, stoplightModel) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation updateIndicatorColor($snapshotId: Long, $stoplightModel:SnapshotStoplightModelInput) {updateIndicatorColor(snapshotId: $snapshotId, stoplightModel:$stoplightModel){successful}}',
      variables: {
        snapshotId,
        stoplightModel,
      },
    }),
  })
}
