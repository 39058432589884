import { useLocation } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { updateNavigationHistory } from '~/redux/slices/currentDraft'
import { useRole } from '~/utils/hooks/useRole'
import { ROLES } from '~/utils/role-utils'

export default function LifemapNav() {
  const location = useLocation()

  const dispatch = useAppDispatch()
  const currentDraft = useAppSelector(state => state.currentDraft)

  const intercom = useIntercom()
  const { userHasAnyRole } = useRole()

  const url = location.pathname
  const { state } = location

  const containsLifemapPath = url.includes('lifemap')
  const containsLifemapFinalPaths =
    containsLifemapPath &&
    (url.includes('final') || url.includes('send-lifemap'))

  const isFamily = userHasAnyRole([ROLES.FAMILY])
  const isSurveyor = userHasAnyRole([ROLES.SURVEY_TAKER])

  const isSurveyorAndIsLifemapPath =
    isSurveyor && containsLifemapPath && !containsLifemapFinalPaths

  useEffect(() => {
    if (!currentDraft) return

    const isOnDisclaimerPage = url === '/lifemap/disclaimer'
    const isOnPrivacyPage = url === '/lifemap/privacy'
    const isOnTermsPage = url === '/lifemap/terms'

    const { lifemapNavHistory = [] } = structuredClone(currentDraft)
    const isPageInHistory = lifemapNavHistory.some(
      history => history.url === url,
    )

    const isOnSpecialPages =
      isOnDisclaimerPage || isOnPrivacyPage || isOnTermsPage

    if (!isPageInHistory && !isOnSpecialPages) {
      const updatedHistory = [...lifemapNavHistory, { url, state }]
      dispatch(updateNavigationHistory(updatedHistory))
    }
  }, [url, state, currentDraft, dispatch])

  useEffect(() => {
    if (isSurveyorAndIsLifemapPath || isFamily) {
      intercom.shutdown()
    } else {
      if (!intercom.isOpen) {
        intercom.boot()
      }
    }

    return () => {
      intercom.boot()
    }
  }, [intercom, isSurveyorAndIsLifemapPath, isFamily])

  return <div />
}
